import React, { useState } from 'react';
import AddUser from '../../components/admin/addUser/AddUser';
import ManageAds from '../../components/admin/ads/ManageAds';
import FullProfile from '../../components/admin/fullProfile/FullProfile';
import LiveStream from '../../components/admin/liveStream/LiveStream';
import ManageLogo from '../../components/admin/logo/ManageLogo';
import UserPermissions from '../../components/admin/userPermissions/UserPermissions';
import './Admin.css';

const Admin = () => {

    const [userPermissions, setUserPermissions] = useState(true);
    const [addUser, setAddUser] = useState(false);
    const [addProfile, setAddProfile] = useState(false);
    const [liveStream, setLiveStream] = useState(false);
    const [manageAds, setManageAds] = useState(false);
    const [manageLogo, setManageLogo] = useState(false);

    const handleUserPermissionsClick = () => {
        setUserPermissions(true);
        setAddUser(false);
        setLiveStream(false);
        setManageAds(false);
        setManageLogo(false);
        setAddProfile(false);
    }

    const handleAddUserClick = () => {
        setUserPermissions(false);
        setAddUser(true);
        setLiveStream(false);
        setManageAds(false);
        setManageLogo(false);
        setAddProfile(false);
    }

    const handleLiveStreamClick = () => {
        setUserPermissions(false);
        setAddUser(false);
        setLiveStream(true);
        setManageAds(false);
        setManageLogo(false);
        setAddProfile(false);
    }

    const handleManageAdsClick = () => {
        setUserPermissions(false);
        setAddUser(false);
        setLiveStream(false);
        setManageAds(true);
        setManageLogo(false);
        setAddProfile(false);
    }

    const handleManageLogoClick = () => {
        setUserPermissions(false);
        setAddUser(false);
        setLiveStream(false);
        setManageAds(false);
        setManageLogo(true);
        setAddProfile(false);
    }

    const handleAddProfileClick = () => {
        setAddProfile(true);
        setUserPermissions(false);
        setAddUser(false);
        setLiveStream(false);
        setManageAds(false);
        setManageLogo(false);
    }

    return (
        <div className='row'>
            <div className='col-md-2 p-3 sidebar'>
                <ul>
                    <li className={userPermissions ? "active" : ''} onClick={handleUserPermissionsClick}>User Permissions</li>
                    <li className={addUser ? "active" : ''} onClick={handleAddUserClick}>Add User</li>
                    {/* <li className={addProfile ? "active" : ''} onClick={handleAddProfileClick}>Add Profile</li> */}
                    <li className={liveStream ? "active" : ''} onClick={handleLiveStreamClick}>Live Stream</li>
                    <li className={manageAds ? "active" : ''} onClick={handleManageAdsClick}>Manage Advertisements</li>
                    <li className={manageLogo ? "active" : ''} onClick={handleManageLogoClick}>Manage Logo</li>
                </ul>
            </div>
            <div className='col-md-10 p-3'>
                {
                    userPermissions ? <UserPermissions /> : null
                }
                {
                    addUser ? <AddUser /> : null
                }
                {
                    addProfile ? <FullProfile /> : null
                }
                {
                    liveStream ? <LiveStream /> : null
                }
                {
                    manageAds ? <ManageAds /> : null
                }
                {
                    manageLogo ? <ManageLogo /> : null
                }
            </div>
        </div>
    )
}

export default Admin