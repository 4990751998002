import { urlConstants } from "./constants";
import axiosInstance from "../../axios";

export const addYoutubeLinkAction = (url) => {
    return async (dispatch) => {
        try {
            dispatch({ type: urlConstants.ADD_YOUTUBE_URL_REQUEST });
            const res = await axiosInstance.post('/livestreamlink/add', url);
            if (res) dispatch({ type: urlConstants.ADD_YOUTUBE_URL_SUCCESS });
        } catch (err) {
            console.log(err);
            dispatch({ type: urlConstants.ADD_YOUTUBE_URL_FAILURE, payload: { err } });
        }
    }
}

export const getYoutubeLinkAction = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: urlConstants.GET_YOUTUBE_URL_REQUEST });
            const res = await axiosInstance.get('/livestreamlink');
            if (res.status === 200)  dispatch({ type: urlConstants.GET_YOUTUBE_URL_SUCCESS, payload: res });
        } catch (error) {
            console.log(error);
            dispatch({ type: urlConstants.GET_YOUTUBE_URL_FAILURE, payload: { error } });
        }
    }
}

export const deleteYoutubeLinkAction = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: urlConstants.DELETE_YOUTUBE_URL_REQUEST });
            const res = await axiosInstance.delete(`/livestreamlink/delete/${id}`);
            if (res.status === 200) dispatch({ type: urlConstants.DELETE_YOUTUBE_URL_SUCCESS, payload: res });
        }
        catch (err) {
            console.log(err);
            dispatch({ type: urlConstants.DELETE_YOUTUBE_URL_SUCCESS, payload: { err } });
        }
    }
}