import React, { useEffect, useState } from 'react';
import './Dashboard.css';
import MultiRangeSlider from '../../components/multiRangeSlider/MultiRangeSlider';
import { MultiSelect } from "react-multi-select-component";
import prabhasPicture from "../../assets/images/prabhas.jpg";
import vamsiPicture from "../../assets/images/4.jpg";
import nodp from "../../assets/images/nodp.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import ProfileDisplay from '../profileDisplay/ProfileDisplay';
import { getYoutubeLinkAction } from '../../redux/actions/youtubeUrl.action';
import { getAllProfilesAction, getMyProfileAction } from '../../redux/actions/user.action';
import { getAllAdsAction } from '../../redux/actions/manageAds.action';
import { generatePublicUrl } from '../../urlConfig';
import Carousel from 'better-react-carousel'


const Dashboard = () => {
    const [minAge, setMinAge] = useState(18);
    const [maxAge, setMaxAge] = useState(40);
    const [selectedProfession, setSelectedProfession] = useState([]);
    const [selectedEducation, setSelectedEducation] = useState([]);
    const [selectedMotherTongue, setSelectedMotherTongue] = useState([]);
    const [selectedSubCaste, setSelectedSubCaste] = useState([]);
    const [liveStream, setLiveStream] = useState(true);
    const [allFullProfiles, setAllFullProfiles] = useState();
    const [youtubeVideoCode, setYoutubeVideoCode] = useState('');
    const [ads, setAds] = useState();

    const authState = useSelector((state) => state.auth);
    const user = JSON.parse(localStorage.getItem("user"));
    const allProfiles = authState.allProfiles;
    const youtubeState = useSelector((state) => state.youtube);
    const adsState = useSelector((state) => state.ads);

    const dispatch = useDispatch();

    let navigate = useNavigate();
    const routeChange = (id) => {
        let path = `/profile/${id}`;
        navigate(path);
    }

    function getAge(dateString) {
        const today = new Date();
        const birthDate = new Date(dateString);
        const age = today.getFullYear() - birthDate.getFullYear();
        let m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }

    const subCasteOptions = [
        { label: "SuryaVamsham", value: "SuryaVamsham" },
        { label: "ChandraVamsham", value: "ChandraVamsham" },
        { label: "Bhatraju", value: "Bhatraju" },
    ];

    const professionOptions = [
        { label: "Banks", value: "Banks" },
        { label: "IT", value: "IT" },
        { label: "Others", value: "Others" },
    ];

    const heightFromOptions = [
        { label: "4ft 0in", value: "4.0" }
    ]

    const educationOptions = [
        { label: "PostGraduation", value: "PostGraduation" },
        { label: "Graduation", value: "Graduation" },
        { label: "Others", value: "Others" },
    ];

    const motherTOngueOptions = [
        { label: "Telugu", value: "Telugu" },
        { label: "Kannada", value: "Kannada" },
        { label: "Kannada", value: "Kannada" },
        { label: "Others", value: "Others" },
    ];

    useEffect(()=>{
        if (authState.authenticate === true) {
            dispatch(getAllProfilesAction());
            dispatch(getYoutubeLinkAction());
            dispatch(getAllAdsAction());
        }
    },[authState.authenticate])

    useEffect(() => {
        dispatch(getAllProfilesAction());
        dispatch(getYoutubeLinkAction());
        dispatch(getAllAdsAction());
    }, [])

    useEffect(() => {
        youtubeState.message === "URL Loaded Successfully..!" &&
            setYoutubeVideoCode(youtubeState.youtubeLiveLink.data[0].youtubeLiveLink.split("=")[1].split("&")[0]);
    }, [youtubeState.message])

    useEffect(() => {
        setAds(adsState.allAds);
    }, [adsState])

    return (
        <div>
            <div className='row'>
                {/* filter */}
                {/* <div className='col-md-3 mt-3 px-5 py-2'>
                    <div className='filterByArea p-3'>
                        <div>Filter By:</div>
                        <div className='d-flex filter-items-wrap-border'>
                            <div>Age: </div>
                            <div className='ms-4 w-100'>
                                <MultiRangeSlider
                                    min={18}
                                    max={40}
                                    onChange={({ min, max }) => { setMaxAge(max); setMinAge(min) }}
                                />
                            </div>
                        </div>
                        <div className='d-flex filter-items-wrap-border'>
                            <div>SubCaste: </div>
                            <div className='ms-4 w-100'>
                                <MultiSelect
                                    options={subCasteOptions}
                                    value={selectedSubCaste}
                                    onChange={setSelectedSubCaste}
                                    labelledBy="Select"
                                />
                            </div>
                        </div>
                        <div className='d-flex filter-items-wrap-border'>
                            <div>Profession: </div>
                            <div className='ms-4 w-100'>
                                <MultiSelect
                                    options={professionOptions}
                                    value={selectedProfession}
                                    onChange={setSelectedProfession}
                                    labelledBy="Select"
                                />
                            </div>
                        </div>
                        <div className='d-flex filter-items-wrap-border'>
                            <div>Height: </div>
                            <div className='ms-4 d-flex w-75' >
                                <select name="profession" id="profession">
                                    <option value="">From...</option>
                                    <option value="5feet 0inches">5feet 0inches</option>
                                    <option value="5feet 0inches">6' 0"</option>
                                    <option value="5feet 0inches">6' 0" +</option>
                                </select>
                                <div>to</div>
                                <select name="profession" id="profession">
                                    <option value="">To...</option>
                                    <option value="5feet 0inches">5feet 0inches</option>
                                    <option value="5feet 0inches">6' 0"</option>
                                    <option value="5feet 0inches">6' 0" +</option>
                                </select>
                            </div>
                        </div>
                        <div className='d-flex filter-items-wrap-border'>
                            <div>Education: </div>
                            <div className='ms-4 w-100'>
                                <MultiSelect
                                    options={educationOptions}
                                    value={selectedEducation}
                                    onChange={setSelectedEducation}
                                    labelledBy="Select"
                                />
                            </div>
                        </div>
                        <div className='d-flex filter-items-wrap-border'>
                            <div>Mother Tongue: </div>
                            <div className='ms-4 w-100'>
                                <MultiSelect
                                    options={motherTOngueOptions}
                                    value={selectedMotherTongue}
                                    onChange={setSelectedMotherTongue}
                                    labelledBy="Select"
                                />
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* profile display area */}
                <div className='col-md-8 mt-3 p-2'>
                    <div className='row justify-content-around mb-4'>
                        {
                            user.role !== 'user' ?
                                allProfiles ?
                                    allProfiles.map((profile) =>
                                        profile._id === user._id
                                            ?
                                            null
                                            :
                                            <div className='cardDisplay' key={profile._id} onClick={() => routeChange(profile._id)}>
                                                {profile.paid === 'yes' ? <div className='paidTag'>paid</div> : null}
                                                <div className='kssIdTag'><span>RKSS/KVPV/{
                                                    profile.kssId.toString().length === 1 ?
                                                        `0000${profile.kssId}`
                                                        :
                                                        profile.kssId.toString().length === 2 ?
                                                            `000${profile.kssId}`
                                                            :
                                                            profile.kssId.toString().length === 3 ?
                                                                `00${profile.kssId}`
                                                                :
                                                                profile.kssId.toString().length === 4 ?
                                                                    `0${profile.kssId}`
                                                                    :
                                                                    `${profile.kssId}`
                                                }</span></div>
                                                <div className='onImageBlackBackgrounddDiv'>
                                                    <div className='ProfileName'>{profile.name}</div>
                                                    <div className='d-flex '>
                                                        <div><span className='detailTitle'>age : </span>{getAge(profile.dob)}</div>
                                                        <div className='marginHorizontal'>,</div>
                                                        <div><span>height : </span>{profile.heightFt}' {profile.heightInches}"</div>
                                                    </div>
                                                    <div className='d-flex'>
                                                        <div><span>caste : </span>{profile.caste} ,</div>
                                                        <div><span>sub-caste : </span>{profile.subCaste}</div>
                                                    </div>
                                                    <div className='d-flex'>
                                                        <div><span>star : </span>{profile.star} ,</div>
                                                        <div><span>Gothram : </span>{profile.gothram}</div>
                                                    </div>
                                                    <div><span>location : </span>{profile.location}</div>
                                                </div>
                                            </div>
                                    )
                                    :
                                    null
                                :
                                user.paid === 'yes' ?
                                    allProfiles ?
                                        allProfiles.map((profile) =>
                                            profile._id === user._id ||
                                                profile.gender === user.gender ||
                                                profile.role !== 'user' ||
                                                profile.matchsettled === 'yes' || profile.optedout === 'yes' || profile.matchsettledfromUs === 'yes'
                                                ?
                                                null
                                                :
                                                <div className='cardDisplay' key={profile._id} onClick={() => routeChange(profile._id)}>
                                                    {/* {
                                                profile.profilePicture === '' ?
                                                    <img src={prabhasPicture} alt="prabhas" />
                                                    :
                                                    <img src={profile.profilePicture} alt={(allProfiles.filter(prof => prof._id == profile.user))[0].name} />
                                            } */}
                                                    <div className='onImageBlackBackgrounddDiv'>
                                                        <div className='ProfileName'>{profile.name}</div>
                                                        <div className='d-flex '>
                                                            <div><span className='detailTitle'>age : </span>{getAge(profile.dob)}</div>
                                                            <div className='marginHorizontal'>,</div>
                                                            <div><span>height : </span>{profile.heightFt}' {profile.heightInches}"</div>
                                                        </div>
                                                        <div className='d-flex'>
                                                            <div><span>caste : </span>{profile.caste} ,</div>
                                                            <div><span>sub-caste : </span>{profile.subCaste}</div>
                                                        </div>
                                                        <div className='d-flex'>
                                                            <div><span>star : </span>{profile.star} ,</div>
                                                            <div><span>Gothram : </span>{profile.gothram}</div>
                                                        </div>
                                                        <div><span>location : </span>{profile.location}</div>
                                                    </div>
                                                </div>
                                        )
                                        :
                                        null
                                    :
                                    <div>
                                        <div>Upgrade to view Profiles. Thank you</div>
                                    </div>
                        }
                    </div>
                </div>
                {/* livestram & ads area */}
                <div className='col-md-4 mt-3'>
                    {
                        youtubeState.message === "URL Loaded Successfully..!" ?
                            <div className='border p-1' style={{ width: "500px", height: "310px" }}>
                                <div>
                                    Live Streaming Event:
                                </div>
                                <div>
                                    <iframe
                                        width="480"
                                        height="270"
                                        src={`https://www.youtube.com/embed/${youtubeVideoCode}?autoplay=1&mute=1`}
                                        sandbox="allow-forms allow-scripts allow-pointer-lock allow-same-origin allow-top-navigation"
                                        allowFullScreen
                                    >
                                    </iframe>
                                </div>
                            </div>
                            :
                            <div className='border p-1 d-flex align-items-center justify-content-center' style={{ width: "500px", height: "310px" }}>
                                <div>
                                    No Live Streaming Event
                                </div>
                            </div>
                    }

                    <div className='border carousel p-2' style={{ width: "500px", height: "310px" }}>
                        <Carousel cols={1} rows={1} gap={10} loop>
                            {
                                ads ?
                                    ads.map(ad => {
                                        return (
                                            <Carousel.Item key={ad._id}>
                                                <img className='carouselImage' src={generatePublicUrl(ad.adPicture)} />
                                                <div className='adDetails w-100 text-center'>
                                                    <h4>{ad.adTitle}</h4>
                                                    <div>{ad.adBrief}</div>
                                                </div>
                                            </Carousel.Item>
                                        )
                                    })
                                    :
                                    null
                            }
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dashboard