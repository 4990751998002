import { userConstants } from "../actions/constants";

const initialState = {
    token: '',
    // name, username, password, dob, height, weight, languagesKnown, gender, maritalStatus, motherTongue, physicalStatus
    user: {
        name: '',
        username: '',
        password: '',
        dob: '',
        height: '',
        weight:  '',
        languagesKnown:  [],
        gender:  '',
        maritalStatus:  '',
        motherTongue:  '',
        physicalStatus:  '',
    },
    message: '',
    authenticating: false,
    authenticate: false,
    allProfiles: [],
    allFullProfiles: [],
    profile: {}
}

export const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case userConstants.SIGNUP_USER_REQUEST:
            return state = {
                ...state,
            }
        case userConstants.SIGNUP_USER_SUCCESS:
            return state = {
                ...state,
            }
        case userConstants.SIGNUP_USER_FAILURE:
            return state = {
                ...state,
                error: action.payload.error
            }
        case userConstants.SIGNIN_USER_REQUEST:
            return state = {
                ...state,
                authenticating: true,
            }
        case userConstants.SIGNIN_USER_SUCCESS:
            return state = {
                ...state,
                token: action.payload.token,
                user: action.payload.user,
                message: 'User Authenticated Successfully...',
                authenticating: false,
                authenticate: true,
            }
        case userConstants.SIGNIN_USER_FAILURE:
            return state = {
                ...state,
                message: 'User Login Failed, Check Credentials...',
                error: action.payload.error,
                authenticating: false,
                authenticate: false
            }
        case userConstants.SIGNOUT_USER_REQUEST:
            return state = {
                ...state,
            }
        case userConstants.SIGNOUT_USER_SUCCESS:
            return state = {
                ...state,
                token: '',
                user: {
                    firstName: '',
                    lastName: '',
                    email: '',
                    mobile: '',
                    role: ''
                },
                message: 'User Logged out',
                authenticate: false
            }
        case userConstants.SIGNOUT_USER_FAILURE:
            return state = {
                ...state,
                error: action.payload.error,
                token: '',
                user: {
                    firstName: '',
                    lastName: '',
                    email: '',
                    mobile: '',
                    role: ''
                },
                message: 'User Logged out',
                authenticate: false
            }
            case userConstants.GET_PROFILES_REQUEST:
                return state;
            case userConstants.GET_PROFILES_SUCCESS:
                return state = {
                    ...state,
                    message: 'Profiles Loaded successfully',
                    allProfiles: action.payload,
                }
            case userConstants.GET_PROFILES_FAILURE:
                return state = {
                    ...state,
                    message: 'Profiles Load Failed',
                    error: action.payload.error
                }
                case userConstants.GET_USER_REQUEST:
                    return state;
                case userConstants.GET_USER_SUCCESS:
                    return state = {
                        ...state,
                        message: 'Profile Loaded successfully',
                        profile: action.payload,
                    }
                case userConstants.GET_USER_FAILURE:
                    return state = {
                        ...state,
                        message: 'Profile Load Failed',
                        error: action.payload.error
                    }

        default:
            return state;
    }
}