import { profileConstants, userConstants } from "./constants";
import axiosInstance from "../../axios";

export const signupAction = (signupData) => {
    return async (dispatch) => {
        try {
            dispatch({ type: userConstants.SIGNUP_USER_REQUEST });
            const res = await axiosInstance.post('/signup', signupData);
            if (res) dispatch({ type: userConstants.SIGNUP_USER_SUCCESS });
        } catch (err) {
            console.log(err);
            dispatch({ type: userConstants.SIGNUP_USER_FAILURE, payload: { err } });
        }
    }
}

export const signinAction = (loginData) => {
    return async (dispatch) => {
        try {
            dispatch({ type: userConstants.SIGNIN_USER_REQUEST });
            const res = await axiosInstance.post('/signin', loginData);
            const { token, user } = res.data;
            localStorage.setItem('token', token);
            localStorage.setItem('user', JSON.stringify(user));
            dispatch({ type: userConstants.SIGNIN_USER_SUCCESS, payload: res.data });
        } catch (err) {
            console.log(err);
            dispatch({ type: userConstants.SIGNIN_USER_FAILURE, payload: { err } });
        }
    }
}

export const signoutAction = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: userConstants.SIGNOUT_USER_REQUEST });
            localStorage.clear();
            await axiosInstance.post('/signout');
            dispatch({ type: userConstants.SIGNOUT_USER_SUCCESS });
        } catch (err) {
            console.log(err);
            dispatch({ type: userConstants.SIGNOUT_USER_FAILURE, payload: { err } });
        }
    }
}


export const getMyProfileAction = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: userConstants.GET_USER_REQUEST });
            const res = await axiosInstance.get('/myprofile');
            dispatch({ type: userConstants.GET_USER_SUCCESS, payload: res.data });
        } catch (error) {
            console.log(error);
            dispatch({ type: userConstants.GET_USER_FAILURE, payload: { error } });
        }
    }
}

export const getAllProfilesAction = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: userConstants.GET_PROFILES_REQUEST });
            const res = await axiosInstance.get('/allprofiles');
            dispatch({ type: userConstants.GET_PROFILES_SUCCESS, payload: res.data });
        } catch (error) {
            console.log(error);
            dispatch({ type: userConstants.GET_PROFILES_FAILURE, payload: { error } });
        }
    }
}

export const updateProfileAction = (id, fullProfile) => {
    return async (dispatch) => {
        try {
            dispatch({ type: profileConstants.UPDATE_PROFILE_REQUEST });
            const res = await axiosInstance.put(`/profile/update/${id}`, fullProfile);
            if (res.status === 200) dispatch({ type: profileConstants.UPDATE_PROFILE_SUCCESS });
        }
        catch (err) {
            console.log(err);
            dispatch({ type: profileConstants.UPDATE_PROFILE_FAILURE, payload: { err } });
        }
    }
}