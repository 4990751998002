import React, { useEffect, useState } from 'react';
import './ContactUs.css';
import { useDispatch, useSelector } from 'react-redux';
import { getLogoAction } from '../../redux/actions/manageLogo.action';

const ContactUs = () => {
    const [logoDetails, setLogoDetails] = useState();

    const dispatch = useDispatch();
    const logoState = useSelector((state) => state.logo);

    useEffect(() => {
        dispatch(getLogoAction());
    }, [])

    useEffect(() => {
        setLogoDetails(logoState.logo[0]);
    }, [logoState]);

    return (
        <div className='screencenterBackground'>
            {
                logoDetails ?
                    <div className='screencenterContent'>
                        <h3>{logoDetails.orgName}</h3>
                        <div className='d-flex orgDetails'>
                            <p>Address: </p>
                            <p className='px-2'>{logoDetails.orgAddress}</p>
                        </div>
                        <div className='d-flex orgDetails'>
                            <p>Contact: </p>
                            <p className='px-2'>{logoDetails.orgContactNumber} ( {logoDetails.orgContactPersonName} )</p>
                        </div>
                        <div className='d-flex orgDetails'>
                            <p>Email: </p>
                            <p className='px-2'>{logoDetails.orgEmail}</p>
                        </div>
                        {/* <tr>
                                <td>Telegram Channel: </td>
                                <td><a href="https://t.me/+Q1IsUcTgx50zZTRl">రాష్ట్రీయ క్షత్రియ సేవా సంఘము, తిరుపతి.</a></td>
                            </tr> */}
                    </div>

                    :
                    <div className='screencenterContent'>
                        <h3>Rashtriya Kshatriya Seva Sangam</h3>
                        <div className='d-flex'>
                            <p> Address: </p>
                            <p> KshatriyaBhavan, KshatriyaNagar, Avilala, Tirupati </p>
                        </div>
                        <div className='d-flex'>
                            <p> Phone: </p>
                            <p> +918686473833 (HemaSundara Varma) </p>
                        </div>
                        <div className='d-flex'>
                            <p> Email: </p>
                            <p> rksstpt1@gmail.com </p>
                        </div>
                        {/* <tr>
                                <td>Telegram Channel: </td>
                                <td><a href="https://t.me/+Q1IsUcTgx50zZTRl">రాష్ట్రీయ క్షత్రియ సేవా సంఘము, తిరుపతి.</a></td>
                            </tr> */}
                    </div>
            }

        </div>
    )
}

export default ContactUs