import React, { useEffect, useState } from 'react';
import './UserPermissions.css';
import { useDispatch, useSelector } from 'react-redux';
import { getAllProfilesAction } from '../../../redux/actions/user.action';
import { updateProfileAction } from '../../../redux/actions/profile.action';

const UserPermissions = () => {
  const [editRoleRowId, setEditRoleRowId] = useState();
  const [editedFormData, setEditedFormData] = useState();

  const dispatch = useDispatch();
  const allUsers = useSelector((state) => state.auth.allProfiles);
  const localUser = JSON.parse(localStorage.getItem("user"));

  const handleEditChange = (e) => {
    const fieldName = e.target.name;
    const fieldValue = e.target.value;

    const formData = { ...editedFormData, [fieldName]: fieldValue };
    setEditedFormData(formData);
  }

  const editHandler = (fullProfile) => {
    setEditRoleRowId(fullProfile._id);
    console.log('editHandler', fullProfile);
    const formData = { ...fullProfile, role: fullProfile.role, updatedBy: localUser._id, paid: fullProfile.paid,
      matchsettled: fullProfile.matchsettled, optedout: fullProfile.optedout, matchsettledfromUs: fullProfile.matchsettledfromUs }

    setEditedFormData(formData);
  }

  const updateHandler = (e, id) => {
    e.preventDefault();

    console.log(editedFormData);
    dispatch(updateProfileAction(id, editedFormData)).then(
      setTimeout(() => {
        dispatch(getAllProfilesAction())
      }, 1000)
    );
    setEditRoleRowId();
  }

  useEffect(() => {
    dispatch(getAllProfilesAction());
  }, [])

  // useEffect(() => {
  //   console.log(editedFormData);
  // }, [editedFormData])

  return (
    <div>
      <table className="table table-striped">
        <thead>
          <tr>
            <th scope="col">S.No</th>
            <th scope="col">KSS ID</th>
            <th scope="col">Full Name</th>
            <th scope="col">Paid</th>
            <th scope="col">Match Settled</th>
            <th scope="col">Match Settled From Us</th>
            <th scope="col">Opted Out</th>
            <th scope="col">Role</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        {
          allUsers && allUsers.map(user => {
            return (
              <tbody>
                {
                  user._id === editRoleRowId ?
                    <tr key={user._id}>
                      <td></td>
                      <td>{user.kssId}</td>
                      <td>{user.name}</td>
                      <td>
                        <select name="matchsettled" id="matchsettled" value={editedFormData.matchsettled} onChange={handleEditChange}>
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </select>
                      </td>
                      <td>
                        <select name="matchsettledfromUs" id="matchsettledfromUs" value={editedFormData.matchsettledfromUs} onChange={handleEditChange}>
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </select>
                      </td>
                      <td>
                        <select name="optedout" id="optedout" value={editedFormData.optedout} onChange={handleEditChange}>
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </select>
                      </td>
                      <td>
                        <select name="paid" id="paid" value={editedFormData.paid} onChange={handleEditChange}>
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </select>
                      </td>
                      <td>
                        {
                          localUser.role === 'superadmin' ?
                            <select name="role" id="role" value={editedFormData.role} onChange={handleEditChange}>
                              <option value="superadmin">Super Admin</option>
                              <option value="admin">Admin</option>
                              <option value="user">User</option>
                            </select>
                            :
                            localUser.role === 'admin' ?
                              <select name="role" id="role" value={editedFormData.role} onChange={handleEditChange}>
                                <option value="admin">Admin</option>
                                <option value="user">User</option>
                              </select>
                              :
                              null
                        }
                      </td>
                      <td>
                        <div className='actionIcons'>
                          <button className='btn btn-success' onClick={(e) => updateHandler(e, user._id)}>Update Role</button>
                        </div>
                      </td>
                    </tr>
                    :
                    <tr key={user._id}>
                      <td></td>
                      <td>{user.kssId}</td>
                      <td>{user.name}</td>
                      <td>{user.matchsettled}</td>
                      <td>{user.matchsettledfromUs}</td>
                      <td>{user.optedout}</td>
                      <td>{user.paid}</td>
                      <td>{user.role}</td>
                      <td>
                        <div className='actionIcons'>
                          <button className='btn btn-secondary' onClick={() => editHandler(user)}>Edit</button>
                        </div>
                      </td>
                    </tr>
                }
              </tbody>
            )
          })
        }
      </table>
    </div>
  )
}

export default UserPermissions