import React, { useEffect, useState } from 'react';
import './Navbar.css';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { getLogoAction } from '../../redux/actions/manageLogo.action';
import { generatePublicUrl } from '../../urlConfig';

const Navbar = () => {
    const [logo, setLogo] = useState('');

    const user = JSON.parse(localStorage.getItem("user"));
    const logoState = useSelector((state) => state.logo);

    const dispatch = useDispatch();
    let navigate = useNavigate();
    const profile = () => {
        let path = `/profile/${user._id}`;
        navigate(path);
    }

    const logoutHandler = () => {
        localStorage.clear();
        let path = `/login`;
        navigate(path);
    }

    useEffect(() => {
        dispatch(getLogoAction());
    }, [])

    useEffect(() => {
        setLogo(logoState.logo[0]);
    }, [logoState])

    return (
        <nav className="navbar navbar-expand-lg bg-light">
            <div className="container-fluid">
                <a className="navbar-brand" href="/">
                    {
                        logo ?
                            <div className='d-flex align-items-center'>
                                <div className='px-2'>
                                    <img src={generatePublicUrl(logo.orgLogo)} alt="logo" height="50px" />
                                </div>
                                <div>
                                    <p className='orgName d-flex align-items-center'>{logo.orgName} 
                                        <div className='d-flex orgDetails px-1'>
                                            <p>(Reg No: </p>
                                            <p className='px-2'>{logo.orgNumber})</p>
                                        </div>
                                    </p>
                                    <div className='d-flex '>
                                        <div className='d-flex orgDetails'>
                                            <p>Address: </p>
                                            <p className='px-2'>{logo.orgAddress}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <div>RKSS - TPTY</div>
                    }
                </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent" style={{ position: "absolute", right: "50px" }}>
                    {/* <form className="d-flex" role="search">
                        <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
                        <button className="btn btn-outline-success" type="submit">Search</button>
                    </form> */}
                    <ul className="navbar-nav ml-auto mb-2 mb-lg-0">
                        {
                            user ?
                                <li className="nav-item">
                                    <a className="nav-link" aria-current="page" href="/">Home</a>
                                </li>
                                :
                                null
                        }
                        {
                            user ?
                                user.role === "superadmin" || user.role === "admin" ?
                                    <div className='d-flex'>
                                        {/* <li className="nav-item">
                                        <a className="nav-link" href="/register">Signup</a>
                                    </li> */}
                                        <li className="nav-item">
                                            <a className="nav-link" aria-current="page" href="/admin">Admin Zone</a>
                                        </li>
                                    </div>
                                    :
                                    null
                                :
                                null
                        }
                        <li className="nav-item">
                            <a className="nav-link" href="/contact-us">Contact Us</a>
                        </li>
                        {
                            user ?
                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false" href="/">
                                        {user && user.name}
                                    </a>
                                    <ul className="dropdown-menu">
                                        <li><a className="dropdown-item" onClick={() => profile()}>Profile</a></li>
                                        <li><hr className="dropdown-divider" /></li>
                                        <li><a className="dropdown-item" onClick={() => logoutHandler()}>Logout</a></li>
                                    </ul>
                                </li>
                                :
                                <li className="nav-item">
                                    <a className="nav-link" href="/login">Login</a>
                                </li>
                        }
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default Navbar