import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const PrivateRoute = () => {
    const token = window.localStorage.getItem('token');
    return (
        token === null ?
            <Navigate to={'/login'} />
            :
            <Outlet />
    )
};


export default PrivateRoute;