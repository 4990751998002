import { combineReducers } from 'redux';
import { adsReducer } from './manageAds.reducer';
import { logoReducer } from './manageLogo.reducer';
import { fullProfileReducer } from './profile.reducer';
import { authReducer } from './user.reducer';
import { urlReducer } from './youtubeUrl.reducer';


const rootReducer = combineReducers({
    auth: authReducer,
    youtube: urlReducer,
    fullProfile: fullProfileReducer,
    ads: adsReducer,
    logo: logoReducer,
});

export default rootReducer;

