import React, { useEffect } from 'react';
import './EditProfileView.css';
import '../../containers/profileDisplay/ProfileDisplay.css';
import { generatePublicUrl } from '../../urlConfig';
import { FaEdit } from 'react-icons/fa';
import { GrUpdate } from 'react-icons/gr';



const EditProfileView = ({ selectedProfile, selectedFullProfile, user, setEditMode, getAge, editedFormData, handleEditChange, updateHandler, handleImageChange }) => {

    useEffect(() => {
        // console.log("selectedFullProfile: ", selectedFullProfile);
        // console.log("selectedProfile: ", selectedProfile);
        // console.log("editedFormData: ", editedFormData);
    }, [editedFormData])

    const badhabitsDropDownOptions = [
        { value: "Doesn't Matter", Title: "Doesn't Matter" },
        { value: "no", Title: 'no' },
        { value: "social/ occasional", Title: 'social/ occasional' },
    ]

    const yesNoDropDownOptions = [
        { value: "yes", Title: "yes" },
        { value: "no", Title: 'no' },
    ]

    return (
        <div>
            <div className='customContainer' key={selectedProfile._id}>
                <div className='d-flex justify-content-between align-items-center '>
                    <div className='customContainerTitle'>Personal Details:</div>
                    <div>
                        {
                            selectedProfile._id === user._id ?
                                <button className='btn btn-success d-flex justify-content-between align-items-center' onClick={(e) => { updateHandler(e, selectedProfile._id) }}>
                                    Update
                                </button>
                                :
                                null
                        }
                    </div>
                </div>
                <div className='row justify-content-between'>
                    <div className='col-md-4'>
                        <div><span>ID: </span><span>{selectedProfile.kssId}</span></div>
                        <div className='d-flex align-items-center'>
                            <span>Name: </span><span>
                                <input style={{ padding: 0 }} type="text" name='name' value={editedFormData.name} onChange={handleEditChange} />
                            </span>
                        </div>
                        {/* <div><span>Age: </span><span>{getAge(selectedProfile.dob.split("T"))} years</span></div> */}
                        <div className='d-flex align-items-center'>
                            <span>Age: </span><span>
                                <input style={{ padding: 0 }} type="date" name='dob' value={editedFormData.dob} onChange={handleEditChange} />
                            </span>
                        </div>
                        <div className='d-flex align-items-center'>
                            <span>Religion: </span><span>
                                <input style={{ padding: 0 }} type="text" name='religion' value={editedFormData.religion} onChange={handleEditChange} />
                            </span>
                        </div>
                        <div className='d-flex align-items-center'>
                            <span>Caste: </span><span>
                                <input style={{ padding: 0 }} type="text" name='caste' value={editedFormData.caste} onChange={handleEditChange} />
                            </span>
                        </div>
                        <div className='d-flex align-items-center'>
                            <span>Sub-Caste: </span><span>
                                <input style={{ padding: 0 }} type="text" name='subcaste' value={editedFormData.subcaste} onChange={handleEditChange} />
                            </span>
                        </div>
                        <div className='d-flex align-items-center'>
                            <span>Height: </span><span>
                                <div className='d-flex align-items-center'>
                                    <input style={{ padding: 0, width: "10%" }} type="number" name='heightFt' value={editedFormData.heightFt} onChange={handleEditChange} /> feet
                                    <input style={{ padding: 0, width: "10%" }} type="number" name='heightInches' value={editedFormData.heightInches} onChange={handleEditChange} /> inches
                                </div>
                            </span>
                        </div>
                        <div className='d-flex align-items-center'>
                            <span>Weight: </span><span>
                                <div className='d-flex align-items-center'>
                                    <input style={{ padding: 0 }} type="text" name='Weight' value={editedFormData.Weight} onChange={handleEditChange} /> Kgs
                                </div>
                            </span>
                        </div>
                        <div><span>Languages Known: </span><span>{selectedProfile.languagesKnown.map((lang, index) => {
                            return <span key={index}> {lang}, </span>
                        }
                        )}</span></div>
                        <div className='d-flex align-items-center'>
                            <span>Contact: </span><span>
                                <input style={{ padding: 0 }} type="text" name='contactNumber' value={editedFormData.contactNumber} onChange={handleEditChange} />
                            </span>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div><span></span><span>.</span></div>
                        <div className='d-flex align-items-center'>
                            <span>Gothram: </span><span>
                                <input style={{ padding: 0 }} type="text" name='gothram' value={editedFormData.gothram} onChange={handleEditChange} />
                            </span>
                        </div>
                        <div className='d-flex align-items-center'>
                            <span>Star: </span><span>
                                <input style={{ padding: 0 }} type="text" name='star' value={editedFormData.star} onChange={handleEditChange} />
                            </span>
                        </div>
                        <div className='d-flex align-items-center'>
                            <span>Raasi: </span><span>
                                <input style={{ padding: 0 }} type="text" name='raasi' value={editedFormData.raasi} onChange={handleEditChange} />
                            </span>
                        </div>
                        <div className='d-flex align-items-center'>
                            <span>Location: </span><span>
                                <input style={{ padding: 0 }} type="text" name='location' value={editedFormData.location} onChange={handleEditChange} />
                            </span>
                        </div>
                        <div className='d-flex align-items-center'>
                            <span>Dosham: </span><span>
                                <input style={{ padding: 0 }} type="text" name='dosham' value={editedFormData.dosham} onChange={handleEditChange} />
                            </span>
                        </div>
                        <div className='d-flex align-items-center'>
                            <span>Marital Status: </span><span>
                                <input style={{ padding: 0 }} type="text" name='maritalStatus' value={editedFormData.maritalStatus} onChange={handleEditChange} />
                            </span>
                        </div>
                        <div className='d-flex align-items-center'>
                            <span>Mother Tongue: </span><span>
                                <input style={{ padding: 0 }} type="text" name='motherTongue' value={editedFormData.motherTongue} onChange={handleEditChange} />
                            </span>
                        </div>
                        <div className='d-flex align-items-center'>
                            <span>Physical Status: </span><span>
                                <input style={{ padding: 0 }} type="text" name='physicalStatus' value={editedFormData.physicalStatus} onChange={handleEditChange} />
                            </span>
                        </div>
                    </div>
                    <div className='col-md-4 '>
                        <div className='row'>
                            <div className='col col-md-10'>
                                <div className='imageContainer'>
                                    {/* {
                                            selectedProfile.profilePicture === "" ?
                                                <img src={nodp} alt={selectedProfile.name} />
                                                :
                                                <img src={generatePublicUrl(selectedProfile.profilePicture)} alt={selectedProfile.name} />
                                        } */}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className='customContainer'>
                <div className='d-flex justify-content-between align-items-center '>
                    <div className='customContainerTitle'>Professional Details:</div>
                    <div>
                        {
                            selectedProfile._id === user._id ?
                                <button className='btn btn-success d-flex justify-content-between align-items-center' onClick={(e) => { updateHandler(e, selectedProfile._id) }}>
                                    Update
                                </button>
                                :
                                null
                        }
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='d-flex align-items-center'>
                            <span>Occupation: </span><span>
                                <input style={{ padding: 0 }} type="text" name='pdoccupation' value={editedFormData.pdoccupation} onChange={handleEditChange} />
                            </span>
                        </div>
                        <div className='d-flex align-items-center'><span>Employed In: </span><span>
                            <input style={{ padding: 0 }} type="text" name='pdemployeedIn' value={editedFormData.pdemployeedIn} onChange={handleEditChange} />
                        </span></div>
                        <div className='d-flex align-items-center'><span>Education: </span><span>
                            <input style={{ padding: 0 }} type="text" name='pdeducation' value={editedFormData.pdeducation} onChange={handleEditChange} />
                        </span></div>
                    </div>
                    <div className='col-md-6'>
                        <div className='d-flex align-items-center'><span>Occupation Detail: </span><span>
                            <input style={{ padding: 0 }} type="text" name='pdoccupationDetail' value={editedFormData.pdoccupationDetail} onChange={handleEditChange} />
                        </span></div>
                        <div className='d-flex align-items-center'><span>Annual Income: </span><span>
                            <input style={{ padding: 0 }} type="text" name='pdannualIncome' value={editedFormData.pdannualIncome} onChange={handleEditChange} />
                        </span></div>
                        {/* <div><span>Occupation Detail: </span><span>{selectedFullProfile.professionalDetails.employeedIn}</span></div> */}
                    </div>
                </div>
            </div>
            <div className='customContainer'>
                <div className='d-flex justify-content-between align-items-center '>
                    <div className='customContainerTitle'>Family Details:</div>
                    <div>
                        {
                            selectedProfile._id === user._id ?
                                <button className='btn btn-success d-flex justify-content-between align-items-center' onClick={(e) => { updateHandler(e, selectedProfile._id) }}>
                                    Update
                                </button>
                                :
                                null
                        }
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='d-flex align-items-center'><span>Family Type:</span><span>
                            {/* <input style={{ padding: 0 }} type="text" name='fdfamilyType' value={editedFormData.fdfamilyType} onChange={handleEditChange} /> */}
                            <select name="fdfamilyType" id="fdfamilyType" value={editedFormData.fdfamilyType} onChange={handleEditChange} style={{ border: "1px solid #000000", borderRadius: "4px" }}>
                                <option value="Nuclear">Nuclear</option>
                                <option value="Joint">Joint</option>
                                <option value="Any">Any</option>
                            </select>
                        </span></div>
                        <div className='d-flex align-items-center'><span>Father's Occupation: </span><span>
                            <input style={{ padding: 0 }} type="text" name='fdfatherOccupation' value={editedFormData.fdfatherOccupation} onChange={handleEditChange} />
                        </span></div>
                        <div className='d-flex align-items-center'><span>No. of Brothers:</span><span>
                            <input style={{ padding: 0 }} type="text" name='fdnoOfBrothers' value={editedFormData.fdnoOfBrothers} onChange={handleEditChange} />
                        </span></div>
                        <div className='d-flex align-items-center'><span>No. of Sisters:</span><span>
                            <input style={{ padding: 0 }} type="text" name='fdnoOfSisters' value={editedFormData.fdnoOfSisters} onChange={handleEditChange} />
                        </span></div>
                    </div>
                    <div className='col-md-6'>
                        <div className='d-flex align-items-center'><span>Family Status:</span><span>
                            <input style={{ padding: 0 }} type="text" name='fdfamilyStatus' value={editedFormData.fdfamilyStatus} onChange={handleEditChange} />
                        </span></div>
                        <div className='d-flex align-items-center'><span>Mother's Occupation:</span><span>
                            <input style={{ padding: 0 }} type="text" name='fdmotherOccupation' value={editedFormData.fdmotherOccupation} onChange={handleEditChange} />
                        </span></div>
                        <div className='d-flex align-items-center'><span>Brother/Brothers Married:</span><span>
                            <input style={{ padding: 0 }} type="text" name='fdnoOfBrothersMarried' value={editedFormData.fdnoOfBrothersMarried} onChange={handleEditChange} />
                        </span></div>
                        <div className='d-flex align-items-center'><span>Sister/Sisters Married:</span><span>
                            <input style={{ padding: 0 }} type="text" name='fdnoOfSistersMarried' value={editedFormData.fdnoOfSistersMarried} onChange={handleEditChange} />
                        </span></div>
                    </div>
                </div>
            </div>
            <div className='customContainer'>
                <div className='d-flex justify-content-between align-items-center '>
                    <div className='customContainerTitle'>Partner Preferences:</div>
                    <div>
                        {
                            selectedProfile._id === user._id ?
                                <button className='btn btn-success d-flex justify-content-between align-items-center' onClick={(e) => { updateHandler(e, selectedProfile._id) }}>
                                    Update
                                </button>
                                :
                                null
                        }
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='d-flex align-items-center'><span>Marital Status: </span><span>
                            {/* <input style={{ padding: 0 }} type="text" name='ppmaritalStatus' value={editedFormData.ppmaritalStatus} onChange={handleEditChange} /> */}
                            <select name="ppmaritalStatus" id="ppmaritalStatus" value={editedFormData.ppmaritalStatus} onChange={handleEditChange} style={{ border: "1px solid #000000", borderRadius: "4px" }}>
                                <option value="Unmarried">Unmarried</option>
                                <option value="Divorced">Divorced</option>
                                <option value="Widow">Widow</option>
                            </select>
                        </span></div>
                        <div className='d-flex align-items-center'><span>Age: </span><span>
                            <input style={{ padding: 0 }} type="text" name='ppage' value={editedFormData.ppage} onChange={handleEditChange} />
                        </span></div>
                        <div className='d-flex align-items-center'><span>Height: </span><span>
                            <input style={{ padding: 0 }} type="text" name='ppheight' value={editedFormData.ppheight} onChange={handleEditChange} />
                        </span></div>
                        <div className='d-flex align-items-center'><span>Physical Status: </span><span>
                            {/* <input style={{ padding: 0 }} type="text" name='ppphysicalStatus' value={editedFormData.ppphysicalStatus} onChange={handleEditChange} /> */}
                            <select name="ppphysicalStatus" id="ppphysicalStatus" value={editedFormData.ppphysicalStatus} onChange={handleEditChange} style={{ border: "1px solid #000000", borderRadius: "4px" }}>
                                <option value="Normal">Normal</option>
                                <option value="Any">Any</option>
                            </select>
                        </span></div>
                        <div className='d-flex align-items-center'><span>Eating Habits: </span><span>
                            <select name="ppeatingHabits" id="ppeatingHabits" value={editedFormData.ppeatingHabits} onChange={handleEditChange} style={{ border: "1px solid #000000", borderRadius: "4px" }}>
                                <option value="Eggiterian">Eggiterian</option>
                                <option value="Vegiterian">Vegiterian</option>
                                <option value="Non-Vegiterian">Non-Vegiterian</option>
                            </select>
                        </span></div>
                        <div className='d-flex align-items-center'><span>Drinking Habits: </span><span>
                            <select name="ppdrinkingHabits" id="ppdrinkingHabits" value={editedFormData.ppdrinkingHabits} onChange={handleEditChange} style={{ border: "1px solid #000000", borderRadius: "4px" }}>
                                {
                                    badhabitsDropDownOptions.map((option, index) => {
                                        return <option key={index} value={option.value}>{option.Title}</option>
                                    })
                                }
                            </select>
                        </span></div>
                        <div className='d-flex align-items-center'><span>Smoking Habits: </span><span>
                            <select name="ppsmokingHabits" id="ppsmokingHabits" value={editedFormData.ppsmokingHabits} onChange={handleEditChange} style={{ border: "1px solid #000000", borderRadius: "4px" }}>
                                {
                                    badhabitsDropDownOptions.map((option, index) => {
                                        return <option key={index} value={option.value}>{option.Title}</option>
                                    })
                                }
                            </select>
                        </span></div>
                        <div className='d-flex align-items-center'><span>Kuja Dosham: </span><span>
                            <select name="ppkujaDosham" id="ppkujaDosham" value={editedFormData.ppkujaDosham} onChange={handleEditChange} style={{ border: "1px solid #000000", borderRadius: "4px" }}>
                                {
                                    yesNoDropDownOptions.map((option, index) => {
                                        return <option key={index} value={option.value}>{option.Title}</option>
                                    })
                                }
                            </select>
                        </span></div>
                        <div className='d-flex align-items-center'><span>Religion: </span><span>
                            <input style={{ padding: 0 }} type="text" name='ppreligion' value={editedFormData.ppreligion} onChange={handleEditChange} />
                        </span></div>
                    </div>
                    <div className='col-md-6'>
                        <div className='d-flex align-items-center'><span>SubCaste: </span><span>
                            {/* <input style={{ padding: 0 }} type="text" name='ppsubCaste' value={editedFormData.ppsubCaste} onChange={handleEditChange} /> */}
                            <select name="ppsubCaste" id="ppsubCaste" value={editedFormData.ppsubCaste} onChange={handleEditChange} style={{ border: "1px solid #000000", borderRadius: "4px" }}>
                                <option value="Suryavamsham">Suryavamsham</option>
                                <option value="Chandravamsham">Chandravamsham</option>
                                <option value="Bhatraju">Bhatraju</option>
                                <option value="Any">Any</option>
                            </select>
                        </span></div>
                        <div className='d-flex align-items-center'><span>Star: </span><span>
                            <input style={{ padding: 0 }} type="text" name='ppstar' value={editedFormData.ppstar} onChange={handleEditChange} />

                        </span></div>
                        <div className='d-flex align-items-center'><span>Education: </span><span>
                            <input style={{ padding: 0 }} type="text" name='ppeducation' value={editedFormData.ppeducation} onChange={handleEditChange} />
                        </span></div>
                        <div className='d-flex align-items-center'><span>Occupation: </span><span>
                            <input style={{ padding: 0 }} type="text" name='ppoccupationPref' value={editedFormData.ppoccupationPref} onChange={handleEditChange} />
                        </span></div>
                        <div className='d-flex align-items-center'><span>Annual Income: </span><span>
                            <input style={{ padding: 0 }} type="text" name='ppannualIncome' value={editedFormData.ppannualIncome} onChange={handleEditChange} />
                        </span></div>
                        <div className='d-flex align-items-center'><span>Country: </span><span>
                            <input style={{ padding: 0 }} type="text" name='ppcountry' value={editedFormData.ppcountry} onChange={handleEditChange} />
                        </span></div>
                        <div className='d-flex align-items-center'><span>State: </span><span>
                            <input style={{ padding: 0 }} type="text" name='ppstate' value={editedFormData.ppstate} onChange={handleEditChange} />
                        </span></div>
                        <div className='d-flex align-items-center'><span>CitizenShip: </span><span>
                            <input style={{ padding: 0 }} type="text" name='ppcitizenship' value={editedFormData.ppcitizenship} onChange={handleEditChange} />
                        </span></div>
                        <div className='d-flex align-items-center'><span>Resident Status: </span><span>
                            <input style={{ padding: 0 }} type="text" name='ppresidentStatus' value={editedFormData.ppresidentStatus ? editedFormData.ppresidentStatus : ''} onChange={handleEditChange} />
                        </span></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditProfileView