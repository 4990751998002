import { adConstants } from "./constants";
import axiosInstance from "../../axios";

export const createAdAction = (ad) => {
    return async (dispatch) => {
        try {
            dispatch({ type: adConstants.CREATE_AD_REQUEST });
            const res = await axiosInstance.post('/createad', ad);
            if (res) dispatch({ type: adConstants.CREATE_AD_SUCCESS, payload: res });
        } catch (err) {
            console.log(err);
            dispatch({ type: adConstants.CREATE_AD_FAILURE, payload: { err } });
        }
    }
}

export const getAllAdsAction = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: adConstants.GET_ADS_REQUEST });
            const res = await axiosInstance.get('/allads');
            dispatch({ type: adConstants.GET_ADS_SUCCESS, payload: res });
        } catch (error) {
            console.log(error);
            dispatch({ type: adConstants.GET_ADS_FAILURE, payload: { error } });
        }
    }
}

export const updateAdAction = (id, ad) => {
    return async (dispatch) => {
        try {
            dispatch({ type: adConstants.UPDATE_AD_REQUEST });
            const res = await axiosInstance.put(`/updatead/${id}`, ad);
            console.log(res);
            if (res.status === 200) dispatch({ type: adConstants.UPDATE_AD_SUCCESS, payload: res });
        }
        catch (err) {
            console.log(err);
            dispatch({ type: adConstants.UPDATE_AD_FAILURE, payload: { err } });
        }
    }
}

export const deleteAdAction = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: adConstants.DELETE_AD_REQUEST });
            const res = await axiosInstance.delete(`/deletead/${id}`);
            if (res.status === 200) dispatch({ type: adConstants.DELETE_AD_SUCCESS, payload: res });
        }
        catch (err) {
            console.log(err);
            dispatch({ type: adConstants.DELETE_AD_SUCCESS, payload: { err } });
        }
    }
}