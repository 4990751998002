export const userConstants = {
    SIGNUP_USER_REQUEST: 'SIGNUP_USER_REQUEST',
    SIGNUP_USER_SUCCESS: 'SIGNUP_USER_SUCCESS',
    SIGNUP_USER_FAILURE: 'SIGNUP_USER_FAILURE',

    SIGNIN_USER_REQUEST: 'SIGNIN_USER_REQUEST',
    SIGNIN_USER_SUCCESS: 'SIGNIN_USER_SUCCESS',
    SIGNIN_USER_FAILURE: 'SIGNIN_USER_FAILURE',

    SIGNOUT_USER_REQUEST: 'SIGNOUT_USER_REQUEST',
    SIGNOUT_USER_SUCCESS: 'SIGNOUT_USER_SUCCESS',
    SIGNOUT_USER_FAILURE: 'SIGNOUT_USER_FAILURE',

    GET_USER_REQUEST: 'GET_USER_REQUEST',
    GET_USER_SUCCESS: 'GET_USER_SUCCESS',
    GET_USER_FAILURE: 'GET_USER_FAILURE',

    GET_PROFILES_REQUEST: 'GET_PROFILES_REQUEST',
    GET_PROFILES_SUCCESS: 'GET_PROFILES_SUCCESS',
    GET_PROFILES_FAILURE: 'GET_PROFILES_FAILURE',

    GET_FULL_PROFILES_REQUEST: 'GET_FULL_PROFILES_REQUEST',
    GET_FULL_PROFILES_SUCCESS: 'GET_FULL_PROFILES_SUCCESS',
    GET_FULL_PROFILES_FAILURE: 'GET_FULL_PROFILES_FAILURE',
};

export const urlConstants = {
    ADD_YOUTUBE_URL_REQUEST: 'ADD_YOUTUBE_URL_REQUEST',
    ADD_YOUTUBE_URL_SUCCESS: 'ADD_YOUTUBE_URL_SUCCESS',
    ADD_YOUTUBE_URL_FAILURE: 'ADD_YOUTUBE_URL_FAILURE',

    GET_YOUTUBE_URL_REQUEST: 'GET_YOUTUBE_URL_REQUEST',
    GET_YOUTUBE_URL_SUCCESS: 'GET_YOUTUBE_URL_SUCCESS',
    GET_YOUTUBE_URL_FAILURE: 'GET_YOUTUBE_URL_FAILURE',

    DELETE_YOUTUBE_URL_REQUEST: 'DELETE_YOUTUBE_URL_REQUEST',
    DELETE_YOUTUBE_URL_SUCCESS: 'DELETE_YOUTUBE_URL_SUCCESS',
    DELETE_YOUTUBE_URL_FAILURE: 'DELETE_YOUTUBE_URL_FAILURE'
}

export const profileConstants = {
    ADD_PROFILE_REQUEST: 'ADD_PROFILE_REQUEST',
    ADD_PROFILE_SUCCESS: 'ADD_PROFILE_SUCCESS',
    ADD_PROFILE_FAILURE: 'ADD_PROFILE_FAILURE',

    UPDATE_PROFILE_REQUEST: 'UPDATE_PROFILE_REQUEST',
    UPDATE_PROFILE_SUCCESS: 'UPDATE_PROFILE_SUCCESS',
    UPDATE_PROFILE_FAILURE: 'UPDATE_PROFILE_FAILURE'
}

export const adConstants = {
    CREATE_AD_REQUEST: 'CREATE_AD_REQUEST',
    CREATE_AD_SUCCESS: 'CREATE_AD_SUCCESS',
    CREATE_AD_FAILURE: 'CREATE_AD_FAILURE',

    UPDATE_AD_REQUEST: 'UPDATE_AD_REQUEST',
    UPDATE_AD_SUCCESS: 'UPDATE_AD_SUCCESS',
    UPDATE_AD_FAILURE: 'UPDATE_AD_FAILURE',

    GET_ADS_REQUEST: 'GET_ADS_REQUEST',
    GET_ADS_SUCCESS: 'GET_ADS_SUCCESS',
    GET_ADS_FAILURE: 'GET_ADS_FAILURE',

    DELETE_AD_REQUEST: 'DELETE_AD_REQUEST',
    DELETE_AD_SUCCESS: 'DELETE_AD_SUCCESS',
    DELETE_AD_FAILURE: 'DELETE_AD_FAILURE',
}

export const logoConstants = {
    ADD_NEW_LOGO_REQUEST: 'ADD_NEW_LOGO_REQUEST',
    ADD_NEW_LOGO_SUCCESS: 'ADD_NEW_LOGO_SUCCESS',
    ADD_NEW_LOGO_FAILURE: 'ADD_NEW_LOGO_FAILURE',

    GET_LOGO_REQUEST: 'GET_LOGO_REQUEST',
    GET_LOGO_SUCCESS: 'GET_LOGO_SUCCESS',
    GET_LOGO_FAILURE: 'GET_LOGO_FAILURE',

    DELETE_LOGO_REQUEST: 'DELETE_LOGO_REQUEST',
    DELETE_LOGO_SUCCESS: 'DELETE_LOGO_SUCCESS',
    DELETE_LOGO_FAILURE: 'DELETE_LOGO_FAILURE',
}