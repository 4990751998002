import { logoConstants } from "./constants";
import axiosInstance from "../../axios";

export const addNewLogoAction = (logo) => {
    return async (dispatch) => {
        try {
            dispatch({ type: logoConstants.ADD_NEW_LOGO_REQUEST });
            const res = await axiosInstance.post('/addnewlogo', logo);
            if (res) dispatch({ type: logoConstants.ADD_NEW_LOGO_SUCCESS, payload: res });
        } catch (err) {
            console.log(err);
            dispatch({ type: logoConstants.ADD_NEW_LOGO_FAILURE, payload: { err } });
        }
    }
}

export const getLogoAction = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: logoConstants.GET_LOGO_REQUEST });
            const res = await axiosInstance.get('/logo');
            dispatch({ type: logoConstants.GET_LOGO_SUCCESS, payload: res });
        } catch (error) {
            console.log(error);
            dispatch({ type: logoConstants.GET_LOGO_FAILURE, payload: { error } });
        }
    }
}

export const deleteLogoAction = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: logoConstants.GET_LOGO_REQUEST });
            const res = await axiosInstance.delete(`/deletead/${id}`);
            if (res.status === 200) dispatch({ type: logoConstants.DELETE_LOGO_SUCCESS, payload: res });
        }
        catch (err) {
            console.log(err);
            dispatch({ type: logoConstants.DELETE_LOGO_FAILURE, payload: { err } });
        }
    }
}