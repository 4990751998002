import { profileConstants, userConstants } from "./constants";
import axiosInstance from "../../axios";


export const getMyProfileAction = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: userConstants.GET_USER_REQUEST });
            const res = await axiosInstance.get('/myprofile');
            dispatch({ type: userConstants.GET_USER_SUCCESS, payload: res });
        } catch (error) {
            console.log(error);
            dispatch({ type: userConstants.GET_USER_FAILURE, payload: { error } });
        }
    }
}

export const getAllProfilesAction = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: userConstants.GET_PROFILES_REQUEST });
            const res = await axiosInstance.get('/allprofiles');
            dispatch({ type: userConstants.GET_PROFILES_SUCCESS, payload: res });
        } catch (error) {
            console.log(error);
            dispatch({ type: userConstants.GET_PROFILES_FAILURE, payload: { error } });
        }
    }
}

export const updateProfileAction = (id, fullProfile) => {
    return async (dispatch) => {
        try {
            dispatch({ type: profileConstants.UPDATE_PROFILE_REQUEST });
            const res = await axiosInstance.put(`/profile/update/${id}`, fullProfile);
            console.log(res);
            if (res.status === 200) dispatch({ type: profileConstants.UPDATE_PROFILE_SUCCESS });
        }
        catch (err) {
            console.log(err);
            dispatch({ type: profileConstants.UPDATE_PROFILE_FAILURE, payload: { err } });
        }
    }
}