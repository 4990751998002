import React, { useState, useEffect } from 'react';
import './ManageAds.css';
import { useDispatch, useSelector } from 'react-redux';
import { createAdAction, deleteAdAction, getAllAdsAction, updateAdAction } from '../../../redux/actions/manageAds.action';
import { generatePublicUrl } from '../../../urlConfig';


const ManageAds = () => {
  const [adPicture, setAdPicture] = useState();
  const [adTitle, setAdTitle] = useState();
  const [adBrief, setAdBrief] = useState();
  const [ads, setAds] = useState([]);
  const [editAdId, setEditAdId] = useState();
  const [editedFormData, setEditedFormData] = useState();
  const [createNewAd, setCreateNewAd] = useState(false);

  const dispatch = useDispatch();
  const adsState = useSelector((state) => state.ads);

  const addNewAd = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('adPicture', adPicture);
    formData.append('adTitle', adTitle);
    formData.append('adBrief', adBrief);


    dispatch(createAdAction(formData)).then(
      setTimeout(() => {
        dispatch(getAllAdsAction())
      }, 1000)
    );
    setCreateNewAd(false);
  }

  const handleImageChange = (e) => {
    setAdPicture(e.target.files[0]);
    // console.log(adPicture.filename)
  }

  const handleEditChange = (e) => {
    const fieldName = e.target.name;
    const fieldValue = e.target.value;

    const formData = { ...editedFormData, [fieldName]: fieldValue };
    setEditedFormData(formData);
  }

  const editHandler = (editedForm) => {
    setEditAdId(editedForm._id);

    console.log('editHandler', editedForm);
    const formData = {
      adPicture: adPicture,
      adTitle: editedForm.adTitle,
      adBrief: editedForm.adBrief,
    }

    setEditedFormData(formData);
  }

  const updateHandler = (e, id) => {
    e.preventDefault();

    console.log(editedFormData);

    const formData = new FormData();
    formData.append('adPicture', editedFormData.adPicture);
    formData.append('adTitle', editedFormData.adTitle);
    formData.append('adBrief', editedFormData.adBrief);


    dispatch(updateAdAction(id, formData)).then(
      setTimeout(() => {
        dispatch(getAllAdsAction())
      }, 1000)
    );
    setEditAdId();
  }

  const deleteHandler = (id) => {
    const confirm = window.confirm(`Are you sure to delete? : ${ads.filter(ad => ad._id === id)[0].adTitile}`)
    if (confirm) {
      alert(`${ads.filter(ad => ad._id === id)[0].adTitle} Ad Deleted SuccessFully`);
      dispatch(deleteAdAction(id));
      setTimeout(() => {
        dispatch(getAllAdsAction());
      }, 1000);
    }
  }

  useEffect(() => {
    dispatch(getAllAdsAction());
  }, [])

  useEffect(() => {
    setAds(adsState.allAds);
  }, [adsState])

  return (
    <div className='manageAds'>
      <div>
        {
          createNewAd ?
            <button className='btn btn-secondary' onClick={() => { setCreateNewAd(false) }}>cancel</button>
            :
            <button className='btn btn-success' onClick={() => { setCreateNewAd(true) }}>Create New Ad</button>
        }
      </div>
      {
        ads ?
          ads.map(ad => {
            return (
              <div>

                {
                  createNewAd ?
                    <div className='manageAdCard'>
                      <div className='d-flex align-items-center justify-content-between'>
                        <h4>Create New Ad:</h4>
                        <div>
                          <button className='btn btn-success m-2' onClick={addNewAd}>Create</button>
                        </div>
                      </div>
                      <div className='adImageContainer'>
                        <input style={{ Width: '200px', height: '100px' }} type="file" name='adPicture' onChange={(e) => setAdPicture(e.target.files[0])} />
                      </div>
                      <div className='d-flex align-items-center'>
                        <span>Title: </span><span>
                          <input style={{ padding: 0, minWidth: "500px" }} type="text" name='adTitle' value={adTitle} onChange={(e) => setAdTitle(e.target.value)} />
                        </span>
                      </div>
                      <div className='d-flex align-items-center'>
                        <span>Brief/Description: </span><span>
                          <input style={{ padding: 0, minWidth: "500px" }} type="text" name='adBrief' value={adBrief} onChange={(e) => setAdBrief(e.target.value)} />
                        </span>
                      </div>
                    </div>
                    :
                    null
                }
                {
                  editAdId === ad._id ?
                    <div className='manageAdCard'>
                      <div className='d-flex align-items-center justify-content-between'>
                        <h4>#Ad {ads.indexOf(ad) + 1}:</h4>
                        <div>
                          <button className='btn btn-success m-2' onClick={(e) => { updateHandler(e, ad._id) }}>Update</button>
                        </div>
                      </div>
                      <div className='adImageContainer'>
                        <input style={{ Width: '200px', height: '100px' }} type="file" name='adPicture' onChange={handleImageChange} />
                      </div>
                      {/* <h6>Title: {ad.adTitle}</h6> */}
                      <div className='d-flex align-items-center'>
                        <span>Title: </span><span>
                          <input style={{ padding: 0, minWidth: "500px" }} type="text" name='adTitle' value={editedFormData.adTitle} onChange={handleEditChange} />
                        </span>
                      </div>
                      {/* <p>Brief/Description: {ad.adBrief}</p> */}
                      <div className='d-flex align-items-center'>
                        <span>Brief/Description: </span><span>
                          <input style={{ padding: 0, minWidth: "500px" }} type="text" name='adBrief' value={editedFormData.adBrief} onChange={handleEditChange} />
                        </span>
                      </div>
                    </div>
                    :
                    <div className='manageAdCard'>
                      <div className='d-flex align-items-center justify-content-between'>
                        <h4>#Ad {ads.indexOf(ad) + 1}:</h4>
                        <div>
                          <button className='btn btn-secondary m-2' onClick={() => editHandler(ad)} >Edit</button>
                          <button className='btn btn-secondary m-2' onClick={() => deleteHandler(ad._id)}>Delete</button>
                        </div>
                      </div>
                      <div className='adImageContainer'>
                        <img src={generatePublicUrl(ad.adPicture)} alt={`Ad ${ads.indexOf(ad) + 1} Image`} style={{ Width: '200px', height: '100px' }} />
                      </div>
                      <h6>Title: {ad.adTitle}</h6>
                      <p>Brief/Description: {ad.adBrief}</p>
                    </div>
                }
              </div>
            )
          })
          :
          <p> No Ads to Display</p>
      }
    </div>
  )
}

export default ManageAds