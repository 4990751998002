import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addYoutubeLinkAction, deleteYoutubeLinkAction, getYoutubeLinkAction } from '../../../redux/actions/youtubeUrl.action';
import './LiveStream.css';

const LiveStream = () => {
  const [youtubeLiveLink, setYoutubeLiveLink] = useState('');
  const [youtubeVideoCode, setYoutubeVideoCode] = useState('');

  const dispatch = useDispatch();
  const youtubeState = useSelector((state) => state.youtube);

  const handleAddLink = async () => {
    youtubeLiveLink === '' ?
      alert('please add live youtube link')
      :
      await dispatch(addYoutubeLinkAction({ youtubeLiveLink }));
    dispatch(getYoutubeLinkAction());
  }

  const handleLinkDelete = async (id) => {
    console.log(id);
    await dispatch(deleteYoutubeLinkAction(id));
    dispatch(getYoutubeLinkAction());
  }

  useEffect(() => {
    dispatch(getYoutubeLinkAction());
  }, [])

  useEffect(() => {
    youtubeState.message === "URL Loaded Successfully..!" &&
      setYoutubeVideoCode(youtubeState.youtubeLiveLink.data[0].youtubeLiveLink.split("=")[1].split("&")[0]);
  }, [youtubeState.message])

  return (
    <div className='liveStream'>
      <div className='row'>
        <div className='col col-md-12'>
          {
            youtubeState.message === "URL Loaded Successfully..!" ?
              youtubeState.youtubeLiveLink.data[0].youtubeLiveLink === '' ?
                <button className='btn btn-success' onClick={() => handleAddLink()}>Add New Link</button>
                :
                <button className='btn btn-danger' onClick={() => handleLinkDelete(youtubeState.youtubeLiveLink.data[0]._id)}>Delete Link</button>
              :
              <button className='btn btn-success' onClick={() => handleAddLink()}>Add New Link</button>
          }
        </div>
      </div>
      <div className='row'>
        <div className='col col-md-12'>
          {
            youtubeState.message === "URL Loaded Successfully..!" ?
              youtubeState.youtubeLiveLink.data[0].youtubeLiveLink === '' ?
                <input type="string" id="link" placeholder="Enter Youtube URL" onChange={(e) => { setYoutubeLiveLink(e.target.value) }} required />
                :
                youtubeState.youtubeLiveLink.data[0].youtubeLiveLink
              :
              <input type="string" id="link" placeholder="Enter Youtube URL" onChange={(e) => { setYoutubeLiveLink(e.target.value) }} required />
          }
        </div>
      </div>
      <div className='row my-4'>
        <div className='col col-md-12'>
          <div className='p-1' style={{ width: "500px", height: "310px" }}>
            <div>
              Live Streaming Event:
            </div>
            <div>
              {
                youtubeState.message === "URL Loaded Successfully..!" ?
                  <iframe
                    width="480"
                    height="270"
                    src={`https://www.youtube.com/embed/${youtubeVideoCode}?autoplay=1&mute=1`}
                    sandbox="allow-forms allow-scripts allow-pointer-lock allow-same-origin allow-top-navigation"
                    allowFullScreen
                  >
                  </iframe>
                  :
                  <p>No Video</p>

              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LiveStream