import { profileConstants } from "../actions/constants";

const initialState = {
    message: '',
}

export const fullProfileReducer = (state = initialState, action) => {
    switch (action.type) {
        case profileConstants.ADD_PROFILE_REQUEST:
            return state = {
                ...state,
            }
        case profileConstants.ADD_PROFILE_SUCCESS:
            return state = {
                ...state,
                message: 'FullProfile created Successfully...'
            }
        case profileConstants.ADD_PROFILE_FAILURE:
            return state = {
                ...state,
                error: action.payload.error,
                message: 'FullProfile created Failed, please Try again.'
            }

            case profileConstants.UPDATE_PROFILE_REQUEST:
                return state = {
                    ...state,
                }
            case profileConstants.UPDATE_PROFILE_SUCCESS:
                return state = {
                    ...state,
                    message: 'FullProfile Updated Successfully...'
                }
            case profileConstants.UPDATE_PROFILE_FAILURE:
                return state = {
                    ...state,
                    error: action.payload.error,
                    message: 'FullProfile Update Failed, please try again.'
                }

        default:
            return state;
    }
}