import { adConstants } from "../actions/constants";

const initialState = {
    allAds: [],
    message: '',
}

export const adsReducer = (state = initialState, action) => {
    switch (action.type) {
        case adConstants.CREATE_AD_REQUEST:
            return state = {
                ...state,
            }
        case adConstants.CREATE_AD_SUCCESS:
            return state = {
                ...state,
                message: action.payload,
            }
        case adConstants.CREATE_AD_FAILURE:
            return state = {
                ...state,
                error: action.payload.error
            }

            case adConstants.UPDATE_AD_REQUEST:
            return state = {
                ...state,
            }
        case adConstants.UPDATE_AD_SUCCESS:
            return state = {
                ...state,
                message: action.payload,
            }
        case adConstants.UPDATE_AD_FAILURE:
            return state = {
                ...state,
                error: action.payload.error
            }

            case adConstants.GET_ADS_REQUEST:
            return state = {
                ...state,
            }
        case adConstants.GET_ADS_SUCCESS:
            return state = {
                ...state,
                allAds: action.payload.data,
                message: "Ads Loaded Successfully...",
            }
        case adConstants.GET_ADS_FAILURE:
            return state = {
                ...state,
                error: action.payload.error,
                message: action.payload.error.message
            }

            case adConstants.DELETE_AD_REQUEST:
            return state = {
                ...state,
            }
        case adConstants.DELETE_AD_SUCCESS:
            return state = {
                ...state,
                message: action.payload,
            }
        case adConstants.DELETE_AD_FAILURE:
            return state = {
                ...state,
                error: action.payload.error
            }

        default:
            return state;
    }
}
