import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLogoAction } from '../../../redux/actions/manageLogo.action';
import { generatePublicUrl } from '../../../urlConfig';

const ManageLogo = () => {
  const [logoDetails, setLogoDetails] = useState();

  const dispatch = useDispatch();
  const logoState = useSelector((state) => state.logo);

  useEffect(() => {
    dispatch(getLogoAction());
  }, [])

  useEffect(() => {
    setLogoDetails(logoState.logo[0]);
  })

  return (
    <div>
      {
        logoDetails ?
          <div className='d-flex align-items-center' >
            <div className='p-2'>
              <img src={generatePublicUrl(logoDetails.orgLogo)} />
            </div>
            <div className='p-2'>
              <div><span>Name: </span><span>{logoDetails.orgName}</span></div>
              <div><span>Address: </span><span>{logoDetails.orgAddress}</span></div>
              <div><span>Reg No: </span><span>{logoDetails.orgNumber}</span></div>
              <div><span>Contact Person: </span><span>{logoDetails.orgContactPersonName}</span></div>
              <div><span>Contact: </span><span>{logoDetails.orgContact}</span></div>
              <div><span>Email: </span><span>{logoDetails.orgEmail}</span></div>
            </div>
          </div>
          :
          <div className='d-flex align-items-center' >
            <div className='p-2'>
              <img src="" alt="..." />
            </div>
            <div className='p-2'>
              <div><span>Name: </span><span>
                {/* <input style={{ padding: 0 }} type="text" name='caste' value={editedFormData.caste} onChange={handleEditChange} /> */}
              </span></div>
              <div><span>Address: </span><span></span></div>
              <div><span>Reg No: </span><span></span></div>
              <div><span>Contact Person: </span><span></span></div>
              <div><span>Contact: </span><span></span></div>
              <div><span>Email: </span><span></span></div>
            </div>
          </div>
      }
    </div>
  )
}

export default ManageLogo