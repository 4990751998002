import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/navbar/Navbar';
import PrivateRoute from './components/privateRoute/PrivateRoute';
import ToastMessages from './components/toast/ToastMessages';
import Admin from './containers/admin/Admin';
import ContactUs from './containers/contactUs/ContactUs';
import Dashboard from './containers/dashboard/Dashboard';
import Login from './containers/login/Login';
import ProfileDisplay from './containers/profileDisplay/ProfileDisplay';
import Signup from './containers/signup/Signup';

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <ToastMessages/>
        <Routes>
          <Route path='/login' exact element={<Login />} />
          <Route path='/register' exact element={<Signup />} />

          <Route path='/' exact element={<PrivateRoute />} >
            <Route path='/' exact element={<Dashboard />} />
          </Route>

          <Route path='/profile/:id' exact element={<PrivateRoute />} >
            <Route path='/profile/:id' exact element={<ProfileDisplay />} />
          </Route>

          <Route path='/contact-us' exact element={<ContactUs />} />

          <Route path='/admin' exact element={<PrivateRoute />} >
            <Route path='/admin' exact element={<Admin />} />
          </Route>


        </Routes>
      </Router>
    </div>
  );
}

export default App;
