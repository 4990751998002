import { logoConstants } from "../actions/constants";

const initialState = {
    logo: [],
    message: '',
}

export const logoReducer = (state = initialState, action) => {
    switch (action.type) {
        case logoConstants.ADD_NEW_LOGO_REQUEST:
            return state = {
                ...state,
            }
        case logoConstants.ADD_NEW_LOGO_SUCCESS:
            return state = {
                ...state,
                message: action.payload,
            }
        case logoConstants.ADD_NEW_LOGO_FAILURE:
            return state = {
                ...state,
                error: action.payload.error
            }

            case logoConstants.GET_LOGO_REQUEST:
            return state = {
                ...state,
            }
        case logoConstants.GET_LOGO_SUCCESS:
            return state = {
                ...state,
                logo: action.payload.data,
                message: "logo Loaded Successfully...",
            }
        case logoConstants.GET_LOGO_FAILURE:
            return state = {
                ...state,
                error: action.payload.error,
                message: action.payload.error.message
            }

            case logoConstants.DELETE_LOGO_REQUEST:
            return state = {
                ...state,
            }
        case logoConstants.DELETE_LOGO_SUCCESS:
            return state = {
                ...state,
                message: action.payload,
            }
        case logoConstants.DELETE_LOGO_FAILURE:
            return state = {
                ...state,
                error: action.payload.error
            }

        default:
            return state;
    }
}
