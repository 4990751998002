import React, { useEffect, useState } from 'react';
import './FullProfile.css';
import { MultiSelect } from "react-multi-select-component";
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getAllProfilesAction, signupAction } from '../../../redux/actions/user.action';

const FullProfile = () => {
    // const [selectedProfileID, setSelectedProfileID] = useState('');
    // const [matchsettled, setMatchsettled] = useState('');
    // const [matchsettledfromUs, setMatchsettledfromUs] = useState('');
    // const [optedout, setOptedout] = useState('');
    // const [profilePicture, setProfilePicture] = useState('');
    // const [occupation, setOccupation] = useState('');
    // const [OccupationDetail, setOccupationDetail] = useState();
    // const [employeedIn , setEmployeedIn] = useState();
    // const [annualIncome , setAnnualIncome] = useState();
    // const [education , setEducation] = useState([]);
    // const [familyType , setFamilyType] = useState([]);
    // const [familyStatus , setFamilyStatus] = useState([]);
    // const [fatherOccupation , setFatherOccupation] = useState('');
    // const [motherOccupation , setMotherOccupation] = useState('');
    // const [noOfBrothers , setNoOfBrothers] = useState('');
    // const [noOfSisters , setNoOfSisters] = useState('');
    // const [noOfBrothersMarried  , setNoOfBrothersMarried] = useState('');
    // const [noOfSistersMarried  , setNoOfSistersMarried] = useState('');
    // const [maritalStatus  , setMaritalStatus] = useState('');
    // const [subCaste  , setSubCaste] = useState('');
    // const [age, setAge] = useState('');
    // const [height, setHeight] = useState('');
    // const [physicalStatus, setPhysicalStatus] = useState('');
    // const [eatingHabits, setEatingHabits] = useState('');
    // const [smokingHabits, setSmokingHabits] = useState('');
    // const [drinkingHabits, setDrinkingHabits] = useState('');
    // const [kujaDosham, setKujaDosham] = useState('');
    // const [religion, setReligion] = useState('');
    // const [star, setStar] = useState('');
    // const [EducationPref, setEducationPref] = useState('');
    // const [occupationPref   , setOccupationPref] = useState('');
    // const [annualIncomePref    , setAnnualIncomePref] = useState('');
    // const [country   , setCountry] = useState('');
    // const [state   , setState] = useState('');
    // const [citizenship   , setCitizenship] = useState('');
    // const [residentStatus    , setResidentStatus] = useState('');

    // const navigate = useNavigate();
    // const dispatch = useDispatch();
    // const signupData = {
    //     name, username, password, dob, height: parseFloat(height), weight: parseFloat(weight), languagesKnown, gender, maritalStatus, motherTongue, physicalStatus
    // }
    // const authState = useSelector((state) => state.auth);
    // const allProfiles = authState.allProfiles.data;

    // const clearSignUpInputs = () => {
    //     setName('');
    //     setPassword('');
    //     setConfirmPassword('');
    //     setDob();
    //     setHeight();
    //     setWeight();
    //     setLanguagesKnown([]);
    //     setGender('');
    //     setMaritalStatus('');
    //     setMotherTongue('');
    //     setPhysicalStatus('');
    // }

    // const togglePassword = () => {
    //     let x = document.getElementById("password");
    //     if (x.type === "password") {
    //         x.type = "text";
    //     } else {
    //         x.type = "password";
    //     }
    //     toggleConfirmPassword();
    // }
    // const toggleConfirmPassword = () => {
    //     let x = document.getElementById("confirmPassword");
    //     if (x.type === "password") {
    //         x.type = "text";
    //     } else {
    //         x.type = "password";
    //     }
    // }

    // const languageKnownOptions = [
    //     { label: "Telugu", value: "telugu" },
    //     { label: "English", value: "english" },
    //     { label: "Kannada", value: "kannada" },
    //     { label: "Tamil", value: "tamil" },
    //     { label: "Odiya", value: "odiya" },
    //     { label: "Malyalam", value: "malyalam" },
    //     { label: "Hindi", value: "hindi" },
    //     { label: "Others", value: "others" },
    // ];

    // const signupFormSubmit = async () => {
    //     if (name.length < 2) {
    //         alert('Name must be atleast 2 characters in Length');
    //     } else {
    //         if (username == 0) {
    //             alert('Username is required');
    //         } else {
    //             if (dob == null) {
    //                 alert('DOB is required');
    //             } else {
    //                 if (height <= 0 || '') {
    //                     alert('Enter your Height');
    //                 }
    //                 else {
    //                     if (weight <= 0 || '') {
    //                         alert('Enter your Weight')
    //                     }
    //                     else {
    //                         if (gender == "" || null) {
    //                             alert("Select your Gender")
    //                         } else {
    //                             if (maritalStatus == "") {
    //                                 alert("Select your Marital Status")
    //                             } else {
    //                                 if (motherTongue == "") {
    //                                     alert("Select yout Mother Tongue")
    //                                 } else {
    //                                     if (password !== confirmPassword) {
    //                                         alert('Password and Confirm Passwords are not matching')
    //                                     } else {
    //                                         await dispatch(signupAction(signupData))
    //                                         alert('User Added');
    //                                     }
    //                                 }
    //                             }
    //                         }
    //                     }
    //                 }
    //             }
    //         }
    //     }
    // }

    // useEffect(() => {
    //     setLanguagesKnownObj(selectedLanguages);
    //     setLanguagesKnown([]);
    //     selectedLanguages && selectedLanguages.map((lang) => {
    //         languagesKnown.push(lang.label);
    //     })
    //     setLanguagesKnown(languagesKnown);
    // }, [selectedLanguages, languagesKnown]);

    // useEffect(() => {
    //     dispatch(getAllProfilesAction());
    // })
    // useEffect(() => {
    //     console.log("allProfiles ", allProfiles);
    //     console.log("selectedProfileID ", selectedProfileID);
    // }, [selectedProfileID])


    return (
        <div>
            Under development
        </div>
        // <div className='addUser'>
        //     <div className="loginForm">
        //         <div className="container">
        //             <div className='sectionTitle'> Add New Profile</div>
        //             <div>
        //                 <select className="form-control" name="gender" id="gender" onChange={(e) => { setSelectedProfileID(e.target.value) }} required >
        //                     <option value="">Select Profile...</option>
        //                     {
        //                         allProfiles && allProfiles.map((profile) =>
        //                             <option value={profile._id}>{profile.name} - {profile.kssId}</option>
        //                         )
        //                     }
        //                 </select>
        //             </div>
        //             <div>
        //                 <input
        //                     className="inputField"
        //                     type="file"
        //                     name="productPicture"
        //                     onChange={(e) => { setProfilePicture(e.target.files[0]) }}
        //                 />
        //             </div>
        //             <form>
        //                 <div className="row">
        //                     <div className="col col-md-6" style={{ margin: 0 }}>
        //                         <input type="string" className="form-control" id="name" placeholder="Full Name" onChange={(e) => { setName(e.target.value) }} required />
        //                     </div>
        //                     <div className="col col-md-6" style={{ margin: 0 }}>
        //                         <input type="string" className="form-control" id="username" placeholder="Username (ex: ABCDDMMYYYY)" onChange={(e) => { setUsername(e.target.value) }} required />
        //                     </div>
        //                 </div>
        //                 <div className="row">
        //                     <div className="col col-md-3" style={{ margin: 0 }}>
        //                         <input type="date" className="form-control" id="dob" placeholder="Date of Birth" onChange={(e) => { setDob(e.target.value) }} required />
        //                     </div>
        //                     <div className="col col-md-3" style={{ margin: 0 }}>
        //                         <input type="number" className="form-control" id="height" placeholder="Height(ex:5.5,5.10)" onChange={(e) => { setHeight(e.target.value) }} required />
        //                     </div>
        //                     <div className="col col-md-3" style={{ margin: 0 }}>
        //                         <input type="number" className="form-control" id="weight" placeholder="Weight(ex:56,75)" onChange={(e) => { setWeight(e.target.value) }} required />
        //                     </div>
        //                     <div className="col col-md-3 d-flex align-items-center justify-content-center" style={{ margin: 0 }}>
        //                         {/* <label for="gender" style={{color: '#ffffff'}}>Gender:</label> */}
        //                         <select className="form-control" name="gender" id="gender" onChange={(e) => { setGender(e.target.value) }} required >
        //                             <option value="">Select Gender...</option>
        //                             <option value="male">Male</option>
        //                             <option value="female">Female</option>
        //                         </select>
        //                     </div>

        //                 </div>
        //                 <div className='row'>
        //                     <div className='col col-md-12 d-flex align-items-center justify-content-center'>
        //                         <span style={{ color: '#000000' }}>Languages Known:</span>
        //                         <MultiSelect
        //                             style={{ width: "100%" }}
        //                             options={languageKnownOptions}
        //                             value={languagesKnownObj}
        //                             onChange={setSelectedLanguages}
        //                             labelledBy="Select Languages Known..."
        //                         />
        //                     </div>
        //                 </div>
        //                 <div className="row">
        //                     <div className="col col-md-4 d-flex align-items-center justify-content-center" style={{ margin: 0 }}>
        //                         <select className="form-control" name="maritalStatus" id="maritalStatus" onChange={(e) => { setMaritalStatus(e.target.value) }} required >
        //                             <option value="">Select Marital Status...</option>
        //                             <option value="unmarried">Unmarried</option>
        //                             <option value="divorced">Divorced</option>
        //                             <option value="widow">Widow</option>
        //                         </select>
        //                     </div>
        //                     <div className="col col-md-4" style={{ margin: 0 }}>
        //                         <select className="form-control" name="motherTongue" id="motherTongue" onChange={(e) => { setMotherTongue(e.target.value) }} required >
        //                             <option value="">select Mother Tongue...</option>
        //                             <option value="telugu">Telugu</option>
        //                             <option value="kannada">Kannada</option>
        //                             <option value="tamil">Tamil</option>
        //                             <option value="malayalam">Malayalam</option>
        //                             <option value="odiya">Odiya</option>
        //                             <option value="hindi">Hindi</option>
        //                             <option value="others">Others</option>
        //                         </select>
        //                     </div>
        //                     <div className="col col-md-4" style={{ margin: 0 }}>
        //                         <select className="form-control" name="physicalStatus" id="physicalStatus" onChange={(e) => { setPhysicalStatus(e.target.value) }} required >
        //                             <option value="">Select Physical Status...</option>
        //                             <option value="normal">Normal</option>
        //                             <option value="handicapped">Handicapped</option>
        //                             <option value="others">Others</option>
        //                         </select>
        //                     </div>
        //                 </div>
        //                 <div className="row">
        //                     <div className="col col-md-6" style={{ margin: 0 }}>
        //                         <input type="password" className="form-control" id="password" placeholder="Password" onChange={(e) => { setPassword(e.target.value) }} required />
        //                         <input type="checkbox" className="form-check-input" id="checkbox" onClick={togglePassword} /> Show Passwords
        //                     </div>
        //                     <div className="col col-md-6" style={{ margin: 0 }}>
        //                         <input type="password" className="form-control" id="confirmPassword" placeholder="Confirm Password" onChange={(e) => { setConfirmPassword(e.target.value) }} required />
        //                     </div>
        //                 </div>
        //             </form>
        //             <div className="row text-center" >
        //                 <div className="col" style={{ marginTop: 0 }}>
        //                     <button className="btn btn-primary mt-2" onClick={signupFormSubmit} style={{ width: "25%" }}>Add User</button>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </div>
    )
}

export default FullProfile