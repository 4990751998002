import React, { useState, useEffect } from 'react';
import './Login.css';
import logo from "../../assets/images/login_background.jpg";
import { signinAction } from '../../redux/actions/user.action';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

const Login = () => {

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const auth = useSelector((state) => state.auth);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const signinFormSubmit = (e) => {
      e.preventDefault();
      if (username.length === 0) {
          alert('Enter your Username');
      } else {
          if (password.length <= 5) {
              alert('Enter your registerd Password')
          } else {
              dispatch(signinAction({ username, password }));
          }
      }
  }

  useEffect(() => {
    if (auth.authenticate) {
        navigate('/');
    }
}, [auth.authenticate]);

  return (
    <div className='login'>
      <img className='login_background_image' src={logo} alt="" />
      <div className="loginForm">
        <div className="container">
          <form>
            <div className="row">
              <div className="col col-md-12" style={{ marginBottom: 0 }}>
                <input className="form-control" id="username" placeholder="Enter your Username" onChange={(e) => { setUsername(e.target.value) }} required />
              </div>
            </div>
            <div className="row">
              <div className="col col-md-12" style={{ marginTop: 0 }}>
                <input type="password" className="form-control" id="password" minLength="6" placeholder="Enter your registered Password" onChange={(e) => { setPassword(e.target.value) }} required />
              </div>
            </div>
          </form>
          <div className="row text-center" >
            <div className="col" style={{ marginTop: 0 }}>
              <button className="btn btn-primary mt-2" onClick={signinFormSubmit}>Signin</button>
            </div>
          </div>
        </div>
        {/* <div className='mt-4 text-center'>
          <span className='forgotPasswordText'>forgot Password?</span> <a href="/">Click Here</a>
        </div> */}
      </div>
    </div>
  )
}

export default Login