import React, { useEffect, useState } from 'react';
import './Signup.css';
import logo from "../../assets/images/login_background.jpg";
import { MultiSelect } from "react-multi-select-component";
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { signupAction } from '../../redux/actions/user.action';

const Signup = () => {
    const [name, setName] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [dob, setDob] = useState();
    const [height, setHeight] = useState();
    const [weight, setWeight] = useState();
    const [languagesKnown, setLanguagesKnown] = useState([]);
    const [languagesKnownObj, setLanguagesKnownObj] = useState([]);
    const [selectedLanguages, setSelectedLanguages] = useState([]);
    const [gender, setGender] = useState('');
    const [maritalStatus, setMaritalStatus] = useState('');
    const [motherTongue, setMotherTongue] = useState('');
    const [physicalStatus, setPhysicalStatus] = useState('');

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const signupData = {
        name, username, password, dob, height: parseFloat(height), weight: parseFloat(weight), languagesKnown, gender, maritalStatus, motherTongue, physicalStatus
    }

    const togglePassword = () => {
        let x = document.getElementById("password");
        if (x.type === "password") {
            x.type = "text";
        } else {
            x.type = "password";
        }
        toggleConfirmPassword();
    }
    const toggleConfirmPassword = () => {
        let x = document.getElementById("confirmPassword");
        if (x.type === "password") {
            x.type = "text";
        } else {
            x.type = "password";
        }
    }

    const languageKnownOptions = [
        { label: "Telugu", value: "telugu" },
        { label: "English", value: "english" },
        { label: "Kannada", value: "kannada" },
        { label: "Tamil", value: "tamil" },
        { label: "Odiya", value: "odiya" },
        { label: "Malyalam", value: "malyalam" },
        { label: "Hindi", value: "hindi" },
        { label: "Others", value: "others" },
    ];

    const signupFormSubmit = () => {
        if (name.length < 2) {
            alert('Name must be atleast 2 characters in Length');
        } else {
            if (username == 0) {
                alert('Username is required');
            } else {
                if (dob == null) {
                    alert('DOB is required');
                } else {
                    if (height <= 0 || '') {
                        alert('Enter your Height');
                    }
                    else {
                        if (weight <= 0 || '') {
                            alert('Enter your Weight')
                        }
                        else {
                            if (gender == "" || null) {
                                alert("Select your Gender")
                            } else {
                                if (maritalStatus == "") {
                                    alert("Select your Marital Status")
                                } else {
                                    if (motherTongue == "") {
                                        alert("Select yout Mother Tongue")
                                    } else {
                                        if (password !== confirmPassword) {
                                            alert('Password and Confirm Passwords are not matching')
                                        } else {
                                            dispatch(signupAction(signupData))
                                                .then(
                                                    navigate('/login')
                                                );
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    useEffect(() => {
        setLanguagesKnownObj(selectedLanguages);
        setLanguagesKnown([]);
        selectedLanguages && selectedLanguages.map((lang) => {
            languagesKnown.push(lang.label);
        })
        setLanguagesKnown(languagesKnown);
    }, [selectedLanguages, languagesKnown])

    return (
        <div className='login'>
            <img className='login_background_image' src={logo} alt="" />
            <div className="loginForm">
                <div className="container">
                    <form>
                        <div className="row">
                            <div className="col col-md-6" style={{ margin: 0 }}>
                                <input type="string" className="form-control" id="name" placeholder="Full Name" onChange={(e) => { setName(e.target.value) }} required />
                            </div>
                            <div className="col col-md-6" style={{ margin: 0 }}>
                                <input type="string" className="form-control" id="username" placeholder="Username (ex: ABCDDMMYYYY)" onChange={(e) => { setUsername(e.target.value) }} required />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col col-md-3" style={{ margin: 0 }}>
                                <input type="date" className="form-control" id="dob" placeholder="Date of Birth" onChange={(e) => { setDob(e.target.value) }} required />
                            </div>
                            <div className="col col-md-3" style={{ margin: 0 }}>
                                <input type="number" className="form-control" id="height" placeholder="Height(ex:5.5,5.10)" onChange={(e) => { setHeight(e.target.value) }} required />
                            </div>
                            <div className="col col-md-3" style={{ margin: 0 }}>
                                <input type="number" className="form-control" id="weight" placeholder="Weight(ex:56,75)" onChange={(e) => { setWeight(e.target.value) }} required />
                            </div>
                            <div className="col col-md-3 d-flex align-items-center justify-content-center" style={{ margin: 0 }}>
                                {/* <label for="gender" style={{color: '#ffffff'}}>Gender:</label> */}
                                <select className="form-control" name="gender" id="gender" onChange={(e) => { setGender(e.target.value) }} required >
                                    <option value="">Select Gender...</option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                </select>
                            </div>

                        </div>
                        <div className='row'>
                            <div className='col col-md-12 d-flex align-items-center justify-content-center'>
                                <span style={{ color: '#ffffff' }}>Languages Known:</span>
                                <MultiSelect
                                    style={{ width: "100%" }}
                                    options={languageKnownOptions}
                                    value={languagesKnownObj}
                                    onChange={setSelectedLanguages}
                                    labelledBy="Select Languages Known..."
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col col-md-4 d-flex align-items-center justify-content-center" style={{ margin: 0 }}>
                                <select className="form-control" name="maritalStatus" id="maritalStatus" onChange={(e) => { setMaritalStatus(e.target.value) }} required >
                                    <option value="">Select Marital Status...</option>
                                    <option value="unmarried">Unmarried</option>
                                    <option value="divorced">Divorced</option>
                                    <option value="widow">Widow</option>
                                </select>
                            </div>
                            <div className="col col-md-4" style={{ margin: 0 }}>
                                <select className="form-control" name="motherTongue" id="motherTongue" onChange={(e) => { setMotherTongue(e.target.value) }} required >
                                    <option value="">select Mother Tongue...</option>
                                    <option value="telugu">Telugu</option>
                                    <option value="kannada">Kannada</option>
                                    <option value="tamil">Tamil</option>
                                    <option value="malayalam">Malayalam</option>
                                    <option value="odiya">Odiya</option>
                                    <option value="hindi">Hindi</option>
                                    <option value="others">Others</option>
                                </select>
                            </div>
                            <div className="col col-md-4" style={{ margin: 0 }}>
                                <select className="form-control" name="physicalStatus" id="physicalStatus" onChange={(e) => { setPhysicalStatus(e.target.value) }} required >
                                    <option value="">Select Physical Status...</option>
                                    <option value="normal">Normal</option>
                                    <option value="handicapped">Handicapped</option>
                                    <option value="others">Others</option>
                                </select>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col col-md-6" style={{ margin: 0 }}>
                                <input type="password" className="form-control" id="password" placeholder="Password" onChange={(e) => { setPassword(e.target.value) }} required />
                                <input type="checkbox" className="form-check-input" id="checkbox" onClick={togglePassword} /> Show Passwords
                            </div>
                            <div className="col col-md-6" style={{ margin: 0 }}>
                                <input type="password" className="form-control" id="confirmPassword" placeholder="Confirm Password" onChange={(e) => { setConfirmPassword(e.target.value) }} required />
                            </div>
                        </div>
                    </form>
                    <div className="row text-center" >
                        <div className="col" style={{ marginTop: 0 }}>
                            <button className="btn btn-primary mt-2" onClick={signupFormSubmit} style={{ width: "25%" }}>Signup</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Signup