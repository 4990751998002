import { urlConstants } from "../actions/constants";

const initialState = {
    youtubeLiveLink: '',
    message: '',
}

export const urlReducer = (state = initialState, action) => {
    switch (action.type) {
        case urlConstants.ADD_YOUTUBE_URL_REQUEST:
            return state = {
                ...state,
            }
        case urlConstants.ADD_YOUTUBE_URL_SUCCESS:
            return state = {
                ...state,
                message: 'URL Added Successfully..!',
            }
        case urlConstants.ADD_YOUTUBE_URL_FAILURE:
            return state = {
                ...state,
                error: action.payload.error
            }

            case urlConstants.GET_YOUTUBE_URL_REQUEST:
            return state = {
                ...state,
            }
        case urlConstants.GET_YOUTUBE_URL_SUCCESS:
            return state = {
                ...state,
                youtubeLiveLink: action.payload,
                message: 'URL Loaded Successfully..!',
            }
        case urlConstants.GET_YOUTUBE_URL_FAILURE:
            return state = {
                ...state,
                error: action.payload.error
            }

            case urlConstants.DELETE_YOUTUBE_URL_REQUEST:
            return state = {
                ...state,
            }
        case urlConstants.DELETE_YOUTUBE_URL_SUCCESS:
            return state = {
                ...state,
                message: 'URL Deleted Successfully..!',
            }
        case urlConstants.DELETE_YOUTUBE_URL_FAILURE:
            return state = {
                ...state,
                error: action.payload.error
            }

        default:
            return state;
    }
}