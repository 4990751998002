import React, { useEffect } from 'react';
import '../../containers/profileDisplay/ProfileDisplay.css';
import { generatePublicUrl } from '../../urlConfig';
import { FaEdit } from 'react-icons/fa';
import { GrUpdate } from 'react-icons/gr';



const ProfileView = ({ selectedProfile, getAge, nodp, user, editHandler }) => {

    return (

        selectedProfile ?
            <div>
                <div className='customContainer' key={selectedProfile._id}>
                    <div className='d-flex justify-content-between align-items-center '>
                        <div className='customContainerTitle'>Personal Details:</div>
                        <div>
                            {
                                selectedProfile._id === user._id ?
                                    <button className='btn btn-secondary d-flex justify-content-between align-items-center' onClick={() => editHandler(selectedProfile)}>
                                        <FaEdit />
                                    </button>
                                    :
                                    null
                            }
                        </div>
                    </div>
                    <div className='row justify-content-between'>
                        <div className='col-md-4'>
                            <div><span>ID: </span><span>RKSS/KVPV/{
                                selectedProfile.kssId.toString().length === 1 ?
                                    `0000${selectedProfile.kssId}`
                                    :
                                    selectedProfile.kssId.toString().length === 2 ?
                                        `000${selectedProfile.kssId}`
                                        :
                                        selectedProfile.kssId.toString().length === 3 ?
                                            `00${selectedProfile.kssId}`
                                            :
                                            selectedProfile.kssId.toString().length === 4 ?
                                                `0${selectedProfile.kssId}`
                                                :
                                                `${selectedProfile.kssId}`
                            }</span></div>
                            <div><span>Name: </span><span>{selectedProfile.name}</span></div>
                            <div><span>Age: </span><span>{getAge(selectedProfile.dob.split("T"))} years</span></div>
                            <div><span>Religion: </span><span>{selectedProfile.religion}</span></div>
                            <div><span>Caste: </span><span>{selectedProfile.caste}</span></div>
                            <div><span>Sub-Caste: </span><span>{selectedProfile.subcaste}</span></div>
                            <div><span>Height: </span><span>{selectedProfile.heightFt}' {selectedProfile.heightInches}"</span></div>
                            <div><span>Weight: </span><span>{selectedProfile.weight} Kgs</span></div>
                            <div><span>Languages Known: </span><span>{selectedProfile.languagesKnown.map((lang, index) => {
                                return <span key={index}> {lang}, </span>
                            }
                            )}</span></div>
                            <div><span>Contact: </span><span>{selectedProfile.contactNumber}</span></div>
                        </div>
                        <div className='col-md-4'>
                            <div><span></span><span>.</span></div>
                            <div><span>Gothram: </span><span>{selectedProfile.gothram}</span></div>
                            <div><span>Star: </span><span>{selectedProfile.star}</span></div>
                            <div><span>Raasi: </span><span>{selectedProfile.raasi}</span></div>
                            <div><span>Location: </span><span>{selectedProfile.location}</span></div>
                            <div><span>Dosham: </span><span>{selectedProfile.dosham}</span></div>
                            <div><span>Marital Status: </span><span>{selectedProfile.maritalStatus}</span></div>
                            <div><span>Mother Tongue: </span><span>{selectedProfile.motherTongue}</span></div>
                            <div><span>Physical Status: </span><span>{selectedProfile.physicalStatus}</span></div>
                        </div>
                        <div className='col-md-4 '>
                            <div className='row'>
                                <div className='col col-md-10'>
                                    <div className='imageContainer'>
                                        {/* {
                                            selectedProfile.profilePicture === "" ?
                                                <img src={nodp} alt={selectedProfile.name} />
                                                :
                                                <img src={generatePublicUrl(selectedProfile.profilePicture)} alt={selectedProfile.name} />
                                        } */}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className='customContainer'>
                    <div className='d-flex justify-content-between align-items-center '>
                        <div className='customContainerTitle'>Professional Details:</div>
                        <div>
                            {
                                selectedProfile._id === user._id ?
                                    <button className='btn btn-secondary d-flex justify-content-between align-items-center' onClick={() => editHandler(selectedProfile)}>
                                        <FaEdit />
                                    </button>
                                    :
                                    null
                            }
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div><span>Occupation: </span><span>{selectedProfile.pdoccupation}</span></div>
                            <div><span>Employed In: </span><span>{selectedProfile.pdemployeedIn}</span></div>
                            <div><span>Education: </span><span>{selectedProfile.pdeducation}</span></div>
                        </div>
                        <div className='col-md-6'>
                            <div><span>Occupation Detail: </span><span>{selectedProfile.pdoccupationDetail}</span></div>
                            <div><span>Annual Income: </span><span>{selectedProfile.pdannualIncome ? parseInt(selectedProfile.pdannualIncome).toLocaleString('en-IN', {
                                maximumFractionDigits: 2,
                                style: 'currency',
                                currency: 'INR'
                            })
                                :
                                0
                            }</span></div>
                        </div>
                    </div>
                </div>
                <div className='customContainer'>
                    <div className='d-flex justify-content-between align-items-center '>
                        <div className='customContainerTitle'>Family Details:</div>
                        <div>
                            {
                                selectedProfile._id === user._id ?
                                    <button className='btn btn-secondary d-flex justify-content-between align-items-center' onClick={() => editHandler(selectedProfile)}>
                                        <FaEdit />
                                    </button>
                                    :
                                    null
                            }
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div><span>Family Type:</span><span>{selectedProfile.fdfamilyType}</span></div>
                            <div><span>Father's Occupation: </span><span>{selectedProfile.fdfatherOccupation}</span></div>
                            <div><span>No. of Brothers:</span><span>{selectedProfile.fdnoOfBrothers}</span></div>
                            <div><span>No. of Sisters:</span><span>{selectedProfile.fdnoOfSisters}</span></div>
                        </div>
                        <div className='col-md-6'>
                            <div><span>Family Status:</span><span>{selectedProfile.fdfamilyStatus}</span></div>
                            <div><span>Mother's Occupation:</span><span>{selectedProfile.fdmotherOccupation}</span></div>
                            <div><span>Brother/Brothers Married:</span><span>{selectedProfile.fdnoOfBrothersMarried}</span></div>
                            <div><span>Sister/Sisters Married:</span><span>{selectedProfile.fdnoOfSistersMarried}</span></div>
                        </div>
                    </div>
                </div>
                <div className='customContainer'>
                    <div className='d-flex justify-content-between align-items-center '>
                        <div className='customContainerTitle'>Partner Preferences:</div>
                        <div>
                            {
                                selectedProfile._id === user._id ?
                                    <button className='btn btn-secondary d-flex justify-content-between align-items-center' onClick={() => editHandler(selectedProfile)}>
                                        <FaEdit />
                                    </button>
                                    :
                                    null
                            }
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div><span>Marital Status: </span><span>{selectedProfile.ppmaritalStatus}</span></div>
                            <div><span>Age: </span><span>{selectedProfile.ppage}</span></div>
                            <div><span>Height: </span><span>{selectedProfile.ppheight}</span></div>
                            <div><span>Physical Status: </span><span>{selectedProfile.ppphysicalStatus}</span></div>
                            <div><span>Eating Habits: </span><span>{selectedProfile.ppeatingHabits}</span></div>
                            <div><span>Drinking Habits: </span><span>{selectedProfile.ppdrinkingHabits}</span></div>
                            <div><span>Smoking Habits: </span><span>{selectedProfile.ppsmokingHabits}</span></div>
                            <div><span>Kuja Dosham: </span><span>{selectedProfile.ppkujaDosham}</span></div>
                            <div><span>Religion: </span><span>{selectedProfile.ppreligion}</span></div>
                        </div>
                        <div className='col-md-6'>
                            <div><span>SubCaste: </span><span>{selectedProfile.ppsubCaste}</span></div>
                            <div><span>Star: </span><span>{selectedProfile.ppstar}</span></div>
                            <div><span>Education: </span><span>{selectedProfile.ppeducation}</span></div>
                            <div><span>Occupation: </span><span>{selectedProfile.ppoccupationPref}</span></div>
                            <div><span>Annual Income: </span><span>{selectedProfile.ppannualIncome}</span></div>
                            <div><span>Country: </span><span>{selectedProfile.ppcountry}</span></div>
                            <div><span>State: </span><span>{selectedProfile.ppstate}</span></div>
                            <div><span>CitizenShip: </span><span>{selectedProfile.ppcitizenship}</span></div>
                            <div><span>Resident Status: </span><span>{selectedProfile.ppresidentStatus}</span></div>
                        </div>
                    </div>
                </div>
            </div>
            :
            <div>
                <div className='customContainer'>
                    <div className='d-flex justify-content-between align-items-center '>
                        <div className='customContainerTitle'>Personal Details:</div>
                        <div>
                            <button className='btn btn-secondary d-flex justify-content-between align-items-center' onClick={() => editHandler(selectedProfile)}>
                                <FaEdit />
                            </button>
                        </div>
                    </div>
                    <div className='row justify-content-between'>
                        <div className='col-md-4'>
                            <div><span>Name: </span></div>
                            <div><span>Age: </span></div>
                            <div><span>Religion: </span></div>
                            <div><span>Caste: </span></div>
                            <div><span>Sub-Caste: </span></div>
                            <div><span>Height: </span></div>
                            <div><span>Weight: </span></div>
                            <div><span>Languages Known: </span></div>
                            <div><span>Contact: </span></div>
                        </div>
                        <div className='col-md-4'>
                            <div><span>Gothram: </span></div>
                            <div><span>Star: </span></div>
                            <div><span>Raasi: </span></div>
                            <div><span>Location: </span></div>
                            <div><span>Dosham: </span></div>
                            <div><span>Marital Status: </span></div>
                            <div><span>Mother Tongue: </span></div>
                            <div><span>Physical Status: </span></div>
                        </div>
                        <div className='col-md-4 imageContainer'>
                            <img src={nodp} alt="prabhas" />
                        </div>
                    </div>
                </div>
                <div className='customContainer'>
                    <div className='d-flex justify-content-between align-items-center '>
                        <div className='customContainerTitle'>Professional Details:</div>
                        <div>
                            <button className='btn btn-secondary d-flex justify-content-between align-items-center'>
                                <FaEdit /> <GrUpdate />
                            </button>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div><span>Occupation: </span></div>
                            <div><span>Employed In: </span></div>
                            <div><span>Education: </span></div>
                        </div>
                        <div className='col-md-6'>
                            <div><span>Occupation Detail: </span></div>
                            <div><span>Annual Income: </span></div>
                            {/* <div><span>Occupation Detail: </span><span>{selectedProfile.professionalDetails.employeedIn}</span></div> */}
                        </div>
                    </div>
                </div>
                <div className='customContainer'>
                    <div className='d-flex justify-content-between align-items-center '>
                        <div className='customContainerTitle'>Family Details:</div>
                        <div>
                            <button className='btn btn-secondary d-flex justify-content-between align-items-center'>
                                <FaEdit /> <GrUpdate />
                            </button>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div><span>Family Type:</span></div>
                            <div><span>Father's Occupation: </span></div>
                            <div><span>No. of Brothers:</span></div>
                            <div><span>No. of Sisters:</span></div>
                        </div>
                        <div className='col-md-6'>
                            <div><span>Family Status:</span></div>
                            <div><span>Mother's Occupation:</span></div>
                            <div><span>Brother/Brothers Married:</span></div>
                            <div><span>Sister/Sisters Married:</span></div>
                        </div>
                    </div>
                </div>
                <div className='customContainer'>
                    <div className='d-flex justify-content-between align-items-center '>
                        <div className='customContainerTitle'>Partner Preferences:</div>
                        <div>
                            <button className='btn btn-secondary d-flex justify-content-between align-items-center'>
                                <FaEdit /> <GrUpdate />
                            </button>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div><span>Marital Status: </span></div>
                            <div><span>Age: </span></div>
                            <div><span>Height: </span></div>
                            <div><span>Physical Status: </span></div>
                            <div><span>Eating Habits: </span></div>
                            <div><span>Drinking Habits: </span></div>
                            <div><span>Smoking Habits: </span></div>
                            <div><span>Kuja Dosham: </span></div>
                            <div><span>Religion: </span></div>
                        </div>
                        <div className='col-md-6'>
                            <div><span>SubCaste: </span></div>
                            <div><span>Star: </span></div>
                            <div><span>Education: </span></div>
                            <div><span>Occupation: </span></div>
                            <div><span>Annual Income: </span></div>
                            <div><span>Country: </span></div>
                            <div><span>State: </span></div>
                            <div><span>CitizenShip: </span></div>
                            <div><span>Resident Status: </span></div>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default ProfileView