import React, { useEffect, useState } from 'react';
import './ProfileDisplay.css';
import nodp from "../../assets/images/nodp.png";
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import ProfileView from '../../components/profileView/ProfileView';
import EditProfileView from '../../components/profileViewEdit/EditProfileView';
import { getAllProfilesAction, getMyProfileAction } from '../../redux/actions/user.action';
import { updateProfileAction } from '../../redux/actions/profile.action';

const ProfileDisplay = (props) => {
    const [selectedProfile, setSelectedProfile] = useState(false);

    const [profilePicture, setprofilePicture] = useState();
    const [pdoccupation, setpdccupation] = useState();
    const [pdoccupationDetail, setpdoccupationDetail] = useState();
    const [pdemployeedIn, setpdemployeedIn] = useState();
    const [pdannualIncome, setpdannualIncome] = useState();
    const [pdeducation, stepdeducation] = useState();
    const [fdfamilyType, setfdfamilyType] = useState();
    const [fdfamilyStatus, setfdfamilyStatus] = useState();
    const [fdfatherOccupation, setfdfatherOccupation] = useState();
    const [fdmotherOccupation, setfdmotherOccupation] = useState();
    const [fdnoOfBrothers, setfdnoOfBrothers] = useState();
    const [fdnoOfSisters, setfdnoOfSisters] = useState();
    const [fdnoOfBrothersMarried, setfdnoOfBrothersMarried] = useState();
    const [fdnoOfSistersMarried, setfdnoOfSistersMarried] = useState();
    const [ppmaritalStatus, setppmaritalStatus] = useState();
    const [ppsubCaste, setppsubCaste] = useState();
    const [ppage, setppage] = useState();
    const [ppheight, setppheight] = useState();
    const [ppphysicalStatus, setppphysicalStatus] = useState();
    const [ppeatingHabits, setppeatingHabits] = useState();
    const [ppsmokingHabits, setppsmokingHabits] = useState();
    const [ppdrinkingHabits, setppdrinkingHabits] = useState();
    const [ppkujaDosham, setppkujaDosham] = useState();
    const [ppreligion, setppreligion] = useState();
    const [ppstar, setppstar] = useState();
    const [ppeducation, setppeducation] = useState();
    const [ppoccupationPref, setppoccupationPref] = useState();
    const [ppannualIncome, setppannualIncome] = useState();
    const [ppcountry, setppcountry] = useState();
    const [ppstate, setppstate] = useState();
    const [ppcitizenship, setppcitizenship] = useState();
    const [ppresidentStatus, setppresidentStatus] = useState();

    const [editMode, setEditMode] = useState(false);
    const [editedFormData, setEditedFormData] = useState({
        matchsettled: '',
        matchsettledfromUs: '',
        optedout: '',
        profilePicture: '',
        professionalDetails: {
            pdoccupation: '',
            pdoccupationDetail: '',
            pdemployeedIn: '',
            pdannualIncome: '',
            pdeducation: '',
        },
        familyDetails: {
            fdfamilyType: '',
            fdfamilyStatus: '',
            fdfatherOccupation: '',
            fdmotherOccupation: '',
            fdnoOfBrothers: '',
            fdnoOfSisters: '',
            fdnoOfBrothersMarried: '',
            fdnoOfSistersMarried: '',
        },
        partnerPreferences: {
            ppmaritalStatus: '',
            ppsubCaste: '',
            ppage: '',
            ppheight: '',
            ppphysicalStatus: '',
            ppeatingHabits: '',
            ppsmokingHabits: '',
            ppdrinkingHabits: '',
            ppkujaDosham: '',
            ppreligion: '',
            ppstar: '',
            ppeducation: '',
            ppoccupationPref: '',
            ppannualIncome: '',
            ppcountry: '',
            ppstate: '',
            ppcitizenship: '',
            ppresidentStatus: '',
        }
    });

    const userid = useParams();
    const user = JSON.parse(localStorage.getItem("user"));
    const allProfiles = useSelector((state) => state.auth.allProfiles);

    const dispatch = useDispatch();

    function getAge(dateString) {
        const today = new Date();
        const birthDate = new Date(dateString);
        const age = today.getFullYear() - birthDate.getFullYear();
        let m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }

    const handleEditChange = (e) => {
        const fieldName = e.target.name;
        const fieldValue = e.target.value;

        const formData = { ...editedFormData, [fieldName]: fieldValue };
        setEditedFormData(formData);
    }

    const handleImageChange = (e) => {
        setprofilePicture(e.target.files[0]);
    }

    const editHandler = (fullProfile) => {
        console.log('editHandler', fullProfile);
        const formData = {
            name: fullProfile.name,
            dob: fullProfile.dob,
            heightFt: fullProfile.heightFt,
            heightInches: fullProfile.heightInches,
            weight: fullProfile.weight,
            languagesKnown: fullProfile.languagesKnown,
            gender: fullProfile.gender,
            maritalStatus: fullProfile.maritalStatus,
            motherTongue: fullProfile.motherTongue,
            physicalStatus: fullProfile.physicalStatus,
            gothram: fullProfile.gothram,
            caste: fullProfile.caste,
            subcaste: fullProfile.subcaste,
            contactNumber: fullProfile.contactNumber,
            religion: fullProfile.religion,
            star: fullProfile.star,
            raasi: fullProfile.raasi,
            dosham: fullProfile.dosham,
            location: fullProfile.location,

            matchsettled: fullProfile.matchsettled,
            matchsettledfromUs: fullProfile.matchsettledfromUs,
            optedout: fullProfile.optedout,

            pdoccupation: fullProfile.pdoccupation,
            pdoccupationDetail: fullProfile.pdoccupationDetail,
            pdemployeedIn: fullProfile.pdemployeedIn,
            pdannualIncome: fullProfile.pdannualIncome,
            pdeducation: fullProfile.pdeducation,

            fdfamilyType: fullProfile.fdfamilyType,
            fdfamilyStatus: fullProfile.fdfamilyStatus,
            fdfatherOccupation: fullProfile.fdfatherOccupation,
            fdmotherOccupation: fullProfile.fdmotherOccupation,
            fdnoOfBrothers: fullProfile.fdnoOfBrothers,
            fdnoOfSisters: fullProfile.fdnoOfSisters,
            fdnoOfBrothersMarried: fullProfile.fdnoOfBrothersMarried,
            fdnoOfSistersMarried: fullProfile.fdnoOfSistersMarried,

            ppmaritalStatus: fullProfile.ppmaritalStatus,
            ppsubCaste: fullProfile.ppsubCaste,
            ppageFrom: fullProfile.ppageFrom,
            ppageTo: fullProfile.ppageTo,
            ppheightFrom: fullProfile.ppheightFrom,
            ppheightFromTo: fullProfile.ppheightTo,
            ppphysicalStatus: fullProfile.ppphysicalStatus,
            ppeatingHabits: fullProfile.ppeatingHabits,
            ppsmokingHabits: fullProfile.ppsmokingHabits,
            ppdrinkingHabits: fullProfile.ppdrinkingHabits,
            ppkujaDosham: fullProfile.ppkujaDosham,
            ppreligion: fullProfile.ppreligion,
            ppstar: fullProfile.ppstar,
            ppeducation: fullProfile.ppeducation,
            ppoccupationPref: fullProfile.ppoccupationPref,
            ppannualIncome: fullProfile.ppannualIncome,
            ppcountry: fullProfile.ppcountry,
            ppstate: fullProfile.ppstate,
            ppcitizenship: fullProfile.ppcitizenship,
            ppresidentStatus: fullProfile.ppresidentStatus,
            updatedBy: user._id,
        }

        setEditedFormData(formData);
        // console.log('EditedFormData', editedFormData);
        setEditMode(true);
    }

    const updateHandler = (e, id) => {
        e.preventDefault();

        console.log(editedFormData);
        dispatch(updateProfileAction(id, editedFormData)).then(
            setTimeout(() => {
                dispatch(getAllProfilesAction())
            }, 1000)
        );
        setEditMode(false);
    }

    useEffect(() => {
        dispatch(getAllProfilesAction());
    }, []);


    useEffect(() => {
        setSelectedProfile(allProfiles.filter(prof => prof._id === userid.id)[0]);

        // console.log("editedForn: ", editedFormData);
    }, [allProfiles, userid]);


    return (
        <div className='container'>
            <div>
                {
                    editMode ?
                        <EditProfileView selectedProfile={selectedProfile} getAge={getAge} user={user} setEditMode={setEditMode} editedFormData={editedFormData} handleEditChange={handleEditChange} updateHandler={updateHandler} handleImageChange={handleImageChange} />
                        :
                        <ProfileView selectedProfile={selectedProfile} getAge={getAge} nodp={nodp} user={user} setEditMode={setEditMode} editHandler={editHandler} />
                }
            </div>
        </div>
    )
}

export default ProfileDisplay