import axios from "axios";
import ToastMessages from "./components/toast/ToastMessages";
import { api } from "./urlConfig";

const token = window.localStorage.getItem("token");

const axiosInstance = axios.create({
    baseURL: api,
    headers: {
        Authorization: token ? `${token}` : "",
    }
});

axiosInstance.interceptors.request.use(
    function (config) {
        // Do something before request is sent
        // console.log('axiosInstance.interceptors.request-config', config);
        return config;
    },
    function (error) {
        // Do something with request error
        // console.log('axiosInstance.interceptors.request-error', error);
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    function (res) {
        // console.log('axiosInstance.interceptors.response-response', res);
        // <ToastMessages res={res} />
        return res;
    },
    function (error) {
        // console.log('axiosInstance.interceptors.response-error', error);
        // return Promise.reject(error);
    }
);

export default axiosInstance;
